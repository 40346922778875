import * as THREE from "three";
import { settings } from "../../../entities/settings";
import { STRETCH_TRIANGLES_RENDER_ORDER } from "../../consts";
import { WebAppUISettingsKeys } from "../../../entities/settings/types";

export class SoStretchTriangle extends THREE.Mesh {
  constructor(points?: THREE.Vector3[]) {
    super(
      points ? new THREE.BufferGeometry().setFromPoints(points) : new THREE.BufferGeometry(),
      new THREE.MeshBasicMaterial({ color: settings.getColorNumber(WebAppUISettingsKeys.selectionColor), transparent: true, opacity: 1.0 })
    );

    if (points?.length) {
      this.add(
        new THREE.Line(new THREE.BufferGeometry().setFromPoints([...points, points[0]]), new THREE.LineBasicMaterial({ transparent: true, opacity: 1.0 }))
      );
    }

    this.renderOrder = STRETCH_TRIANGLES_RENDER_ORDER;
  }

  public raycast(raycaster: THREE.Raycaster, intersects: THREE.Intersection[]): void {
    const oldLength = intersects.length;
    super.raycast(raycaster, intersects);

    if (intersects.length !== oldLength) {
      return;
    }

    const threshold = raycaster.params.Line.threshold;

    // Checking boundingSphere distance to ray
    if (this.geometry.boundingSphere === null) {
      this.geometry.computeBoundingSphere();
    }

    const sphere = this.geometry.boundingSphere.clone();
    sphere.applyMatrix4(this.matrixWorld);
    sphere.radius += threshold;

    if (raycaster.ray.intersectsSphere(sphere) === false) {
      return;
    }

    const localRay = raycaster.ray.clone().applyMatrix4(this.matrixWorld.clone().invert());

    const localThreshold = threshold / ((this.scale.x + this.scale.y + this.scale.z) / 3);
    const localThresholdSq = localThreshold * localThreshold;

    const interSegment = new THREE.Vector3();
    const interRay = new THREE.Vector3();

    const points: THREE.Vector3[] = [];

    if (this.geometry instanceof THREE.BufferGeometry && this.geometry.isBufferGeometry) {
      const index = this.geometry.index;
      const positionAttribute = this.geometry.attributes.position;

      if (index !== null) {
        const indices = index.array;

        for (let i = 0; i < indices.length; i++) {
          points.push(new THREE.Vector3().fromBufferAttribute(positionAttribute, indices[i]));
        }
      } else {
        for (let i = 0; i < positionAttribute.count; i++) {
          points.push(new THREE.Vector3().fromBufferAttribute(positionAttribute, i));
        }
      }
    }

    if (points.length) {
      points.push(points[0]);
    }

    for (let i = 0; i < points.length - 1; i++) {
      const start = points[i];
      const end = points[i + 1];

      const distSq = localRay.distanceSqToSegment(start, end, interRay, interSegment);

      if (distSq > localThresholdSq) {
        continue;
      }

      interRay.applyMatrix4(this.matrixWorld); //Move back to world space for distance calculation
      const distance = raycaster.ray.origin.distanceTo(interRay);

      if (distance < raycaster.near || distance > raycaster.far) {
        continue;
      }

      intersects.push({
        distance: distance,
        point: interSegment.clone().applyMatrix4(this.matrixWorld),
        index: i,
        face: null,
        faceIndex: null,
        object: this,
      });
    }
  }

  public updateHighlight(isHighlighted: boolean): void {
    (this.material as THREE.MeshBasicMaterial).color.setHex(
      settings.getColorNumber(isHighlighted ? WebAppUISettingsKeys.highlightColor : WebAppUISettingsKeys.selectionColor)
    );

    this.children.forEach(child => {
      if (child instanceof THREE.Line) {
        child.material.color.setHex(0);
        child.visible = isHighlighted;
      }
    });
  }
}
