import { Input } from "antd";
import { reaction } from "mobx";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import { SceneEditorMode } from "../../../editor/models/SceneEditorMode";
import UnitsUtils from "../../../editor/utils/UnitsUtils";
import { areaToEnUsLocaleString, feetInchesFractionToInches, inchesToFeetInchesFraction } from "../../../helpers/measures";
import { appModel } from "../../../models/AppModel";
import "./Editor.sass";

const EditorFooter = observer(() => {
  const [x, setX] = useState<string>();
  const [y, setY] = useState<string>();
  const [width, setWidth] = useState<string>();
  const [height, setHeight] = useState<string>();
  const [shift, setShift] = useState<string>();
  const [totalGrossArea, setTotalGrossArea] = useState<string>();
  const [activeFloorGrossArea, setActiveFloorGrossArea] = useState<string>();
  const [activeFloorNetArea, setActiveFloorNetArea] = useState<string>();
  const [isChangingFooterProps, setIsChangingFooterProps] = useState<boolean>(false);

  const activeCorePlan = appModel.activeCorePlan;
  const rooms = activeCorePlan?.getRooms(appModel.selectedRoomsIds) || [];
  const isEnabledRoom = appModel.sceneEditorMode === SceneEditorMode.Room && rooms.length === 1;
  const isEnabledLotLine = appModel.sceneEditorMode === SceneEditorMode.LotLine && appModel.selectedLotItemsIds.length === 1;
  const isEnabledRoomOpening = appModel.sceneEditorMode === SceneEditorMode.RoomOpening && appModel.selectedRoomOpenings.length === 1;
  const lotItem = isEnabledLotLine ? appModel.activeFloor.lotLine.getLotItem(appModel.selectedLotItemsIds[0]) : null;
  const opening = isEnabledRoomOpening ? appModel.selectedRoomOpenings[0] : null;

  const lotSize = areaToEnUsLocaleString(appModel.activeFloor?.lotLine?.getArea() ?? 0);
  const bedroomsCount = appModel.activeCorePlanBedroomsCounts;
  const bathroomsCount = appModel.activeCorePlanBathroomsCounts;

  const totalGrossAreaRequired = activeCorePlan ? areaToEnUsLocaleString(activeCorePlan.attributes.maxGrossArea) : "";
  const lotSizeRequired = activeCorePlan ? areaToEnUsLocaleString(activeCorePlan.attributes.lotSize) : "";
  const bedroomsCountRequired = activeCorePlan ? activeCorePlan.attributes.bedRooms : 0;
  const bathroomsCountRequired = activeCorePlan ? activeCorePlan.attributes.bathRooms : 0;

  const roomSharedValue = rooms.length === 0 ? "n/a" : rooms.length > 1 ? "multi" : null;

  let footerX: string;
  let footerY: string;

  switch (appModel.sceneEditorMode) {
    case SceneEditorMode.Background:
    case SceneEditorMode.Room: {
      footerX = roomSharedValue || inchesToFeetInchesFraction(rooms[0].x, UnitsUtils.getRoundFractionPrecision());
      footerY = roomSharedValue || inchesToFeetInchesFraction(rooms[0].y, UnitsUtils.getRoundFractionPrecision());
      break;
    }
    case SceneEditorMode.LotLine: {
      const lotSharedValue = appModel.selectedLotItemsIds.length === 0 ? "n/a" : appModel.selectedLotItemsIds.length > 1 ? "multi" : null;
      footerX = lotSharedValue || inchesToFeetInchesFraction(lotItem.x, UnitsUtils.getRoundFractionPrecision());
      footerY = lotSharedValue || inchesToFeetInchesFraction(lotItem.y, UnitsUtils.getRoundFractionPrecision());
      break;
    }
  }

  const roomNetWidth = roomSharedValue || inchesToFeetInchesFraction(rooms[0].netWidth, UnitsUtils.getRoundFractionPrecision());
  const roomNetHeight = roomSharedValue || inchesToFeetInchesFraction(rooms[0].netHeight, UnitsUtils.getRoundFractionPrecision());
  const roomArea = roomSharedValue || areaToEnUsLocaleString(rooms[0].area);
  const roomRotation = roomSharedValue || rooms[0].rotation.toFixed();
  const roomMirroring = roomSharedValue || rooms[0].mirroring.toString();
  const roomOpeningShift = isEnabledRoomOpening ? inchesToFeetInchesFraction(opening.referenceShiftDistance, UnitsUtils.getRoundFractionPrecision()) : "n/a";

  const floorGrossAreaMap = useMemo(() => new Map<string, number>(), [appModel.activeCorePlan]);

  useEffect(() => {
    if (appModel.activeFloor) {
      const disposer = reaction(
        () => [
          appModel.includeCladdingThickness,
          appModel.activeFloor ? [appModel.activeFloor.rooms.map(room => [room.x, room.y, room.width, room.height])] : null,
          !appModel.isSceneLoaded,
        ],
        () => {
          if (appModel.activeFloor && appModel.isSceneLoaded) {
            if (floorGrossAreaMap.get(appModel.activeFloor.id) === undefined) {
              appModel.activeCorePlan.floors.forEach(floor => {
                floorGrossAreaMap.set(floor.id, appModel.baseManager.roomManager.tmlTool.calculateGrossArea(floor));
              });
            } else {
              floorGrossAreaMap.set(appModel.activeFloor.id, appModel.baseManager.roomManager.tmlTool.calculateGrossArea(appModel.activeFloor));
            }

            setTotalGrossArea(areaToEnUsLocaleString(Array.from(floorGrossAreaMap.values()).reduce((sum, area) => sum + area, 0)));
            setActiveFloorGrossArea(areaToEnUsLocaleString(floorGrossAreaMap.get(appModel.activeFloor.id)));
            setActiveFloorNetArea(areaToEnUsLocaleString(appModel.baseManager.roomManager.calculateNetArea(appModel.activeFloor)));
          } else {
            setTotalGrossArea("0");
            setActiveFloorGrossArea("0");
            setActiveFloorNetArea("0");
          }
        },
        { fireImmediately: true }
      );

      return () => {
        disposer();
      };
    }
  }, [appModel.activeFloor]);

  useEffect(() => {
    if (!isChangingFooterProps) {
      setX(footerX);
    }
  }, [footerX, isChangingFooterProps]);

  useEffect(() => {
    if (!isChangingFooterProps) {
      setY(footerY);
    }
  }, [footerY, isChangingFooterProps]);

  useEffect(() => {
    if (!isChangingFooterProps) {
      setWidth(roomNetWidth);
    }
  }, [roomNetWidth, isChangingFooterProps]);

  useEffect(() => {
    if (!isChangingFooterProps) {
      setHeight(roomNetHeight);
    }
  }, [roomNetHeight, isChangingFooterProps]);

  useEffect(() => {
    if (!isChangingFooterProps) {
      setShift(roomOpeningShift);
    }
  }, [roomOpeningShift, isChangingFooterProps]);

  const onXChange = useCallback(
    (e: any): void => {
      setIsChangingFooterProps(true);
      setX(e.target.value);

      const parsed = feetInchesFractionToInches(e.target.value);
      if (parsed === null) {
        return;
      }

      switch (appModel.sceneEditorMode) {
        case SceneEditorMode.Room:
          rooms[0].setX(parsed);
          break;
        case SceneEditorMode.LotLine:
          lotItem.setX(parsed);
          break;
      }
    },
    [appModel, rooms, lotItem]
  );

  const onYChange = useCallback(
    (e: any): void => {
      setIsChangingFooterProps(true);
      setY(e.target.value);

      const parsed = feetInchesFractionToInches(e.target.value);
      if (parsed === null) {
        return;
      }

      switch (appModel.sceneEditorMode) {
        case SceneEditorMode.Room:
          rooms[0].setY(parsed);
          break;
        case SceneEditorMode.LotLine:
          lotItem.setY(parsed);
          break;
      }
    },
    [appModel, rooms, lotItem]
  );

  const onWidthChange = useCallback(
    (e: any) => {
      setIsChangingFooterProps(true);
      setWidth(e.target.value);

      const parsed = feetInchesFractionToInches(e.target.value);
      if (parsed !== null) {
        const roomId = rooms[0].id;
        let copyLockedRoomDimensions = { ...appModel.activeCorePlan.lockedRoomDimensions };

        copyLockedRoomDimensions = {
          ...copyLockedRoomDimensions,
          [roomId]: {
            x: true,
            y: copyLockedRoomDimensions && copyLockedRoomDimensions[roomId] ? copyLockedRoomDimensions[roomId].y : false,
          },
        };
        activeCorePlan.setLockedRoomDimensions(copyLockedRoomDimensions);
        rooms[0].setNetWidth(parsed);
      }
    },
    [rooms]
  );

  const onHeightChange = useCallback(
    (e: any) => {
      setIsChangingFooterProps(true);
      setHeight(e.target.value);

      const parsed = feetInchesFractionToInches(e.target.value);
      if (parsed !== null) {
        const roomId = rooms[0].id;

        let copyLockedRoomDimensions = { ...appModel.activeCorePlan.lockedRoomDimensions };

        copyLockedRoomDimensions = {
          ...copyLockedRoomDimensions,
          [roomId]: {
            x: copyLockedRoomDimensions && copyLockedRoomDimensions[roomId] ? copyLockedRoomDimensions[roomId].x : false,
            y: true,
          },
        };

        activeCorePlan.setLockedRoomDimensions(copyLockedRoomDimensions);
        rooms[0].setNetHeight(parsed);
      }
    },
    [rooms]
  );

  const onShiftChange = useCallback(
    (e: any) => {
      setIsChangingFooterProps(true);
      setShift(e.target.value);

      const parsed = feetInchesFractionToInches(e.target.value);
      if (parsed !== null) {
        opening.setReferenceShiftDistance(parsed);
      }
    },
    [opening]
  );

  const onPropsUpdated = useCallback(() => {
    setIsChangingFooterProps(false);
  }, []);

  const onKeyUp = useCallback((e: any) => {
    e.stopPropagation();
  }, []);

  return (
    <div className="editor-footer">
      <Scrollbar noScrollY>
        <div className="editor-footer-toolbar">
          <div className="group">
            {(appModel.sceneEditorMode === SceneEditorMode.Room ||
              appModel.sceneEditorMode === SceneEditorMode.RoomOpening ||
              appModel.sceneEditorMode === SceneEditorMode.RoomWallSelection) && (
              <>
                <div className="item">
                  <p>
                    <span className="space">Gross area:</span>
                    {activeFloorGrossArea}
                    <em className="last">sqft</em>
                  </p>
                </div>
                <div className="toolbutton-separator" />
                <div className="item">
                  <p>
                    <span className="space">Net area:</span>
                    {activeFloorNetArea}
                    <em className="last">sqft</em>
                  </p>
                </div>
                <div className="toolbutton-separator" />
                <div className="item">
                  <p>
                    <span className="space">Total gross area:</span>
                    {totalGrossArea}
                    <em>of</em>
                    {totalGrossAreaRequired}
                    <em className="last">sqft</em>
                  </p>
                </div>
                <div className="toolbutton-separator" />
                <div className="item">
                  <p>
                    <span className="space">Bedrooms:</span>
                    {bedroomsCount}
                    <em>of</em>
                    {bedroomsCountRequired}
                  </p>
                </div>
                <div className="toolbutton-separator" />
                <div className="item">
                  <p>
                    <span className="space">Bathrooms:</span>
                    {bathroomsCount}
                    <em>of</em>
                    {bathroomsCountRequired}
                  </p>
                </div>
              </>
            )}

            {appModel.sceneEditorMode === SceneEditorMode.LotLine && (
              <>
                <div className="toolbutton-separator" />
                <div className="item">
                  <p>
                    <span className="space">Lot size:</span>
                    {lotSize}
                    <em>of</em>
                    {lotSizeRequired}
                    <em className="last">sqft</em>
                  </p>
                </div>
              </>
            )}
          </div>

          <div className="group">
            {appModel.sceneEditorMode === SceneEditorMode.RoomOpening ? (
              <>
                <div className="item">
                  <Input
                    addonBefore="Wall-Centerline:"
                    value={shift}
                    addonAfter="ft"
                    onChange={onShiftChange}
                    onPressEnter={onPropsUpdated}
                    onBlur={onPropsUpdated}
                    onKeyUp={onKeyUp}
                    readOnly={appModel.isViewOnlyMode}
                    disabled={!isEnabledRoomOpening}
                    data-testid="inputShift"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="item">
                  <Input
                    data-testid="inputWidth"
                    addonBefore="W:"
                    value={width}
                    addonAfter="ft"
                    onChange={onWidthChange}
                    onPressEnter={onPropsUpdated}
                    onBlur={onPropsUpdated}
                    onKeyUp={onKeyUp}
                    readOnly={appModel.isViewOnlyMode}
                    disabled={!isEnabledRoom}
                  />
                </div>
                <div className="toolbutton-separator" />

                <div className="item">
                  <Input
                    data-testid="inputHeight"
                    addonBefore="L:"
                    value={height}
                    addonAfter="ft"
                    onChange={onHeightChange}
                    onPressEnter={onPropsUpdated}
                    onBlur={onPropsUpdated}
                    onKeyUp={onKeyUp}
                    readOnly={appModel.isViewOnlyMode}
                    disabled={!isEnabledRoom}
                  />
                </div>
                <div className="toolbutton-separator" />

                <div className="item">
                  <Input
                    data-testid="inputX"
                    addonBefore="X:"
                    value={x}
                    onChange={onXChange}
                    onPressEnter={onPropsUpdated}
                    onBlur={onPropsUpdated}
                    onKeyUp={onKeyUp}
                    readOnly={appModel.isViewOnlyMode}
                    disabled={!isEnabledRoom && !isEnabledLotLine}
                  />
                </div>
                <div className="toolbutton-separator" />

                <div className="item">
                  <Input
                    data-testid="inputY"
                    addonBefore="Y:"
                    value={y}
                    onChange={onYChange}
                    onPressEnter={onPropsUpdated}
                    onBlur={onPropsUpdated}
                    onKeyUp={onKeyUp}
                    readOnly={appModel.isViewOnlyMode}
                    disabled={!isEnabledRoom && !isEnabledLotLine}
                  />
                </div>
              </>
            )}

            <div className="toolbutton-separator" />
            <div className="item">
              <i className="icon icon-rotate"></i>
              <p>{roomRotation}</p>
            </div>
            <div className="toolbutton-separator" />

            <div className="item">
              <i className="icon icon-mirror"></i>
              <p>{roomMirroring}</p>
            </div>
            <div className="toolbutton-separator" />

            <div className="item">
              <p>
                <span>Room size:</span>
                {roomArea}
                <span>sqft</span>
              </p>
            </div>
          </div>
        </div>
      </Scrollbar>
    </div>
  );
});

export default EditorFooter;
