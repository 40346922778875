import log from "loglevel";
import { action, computed, makeObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";
import { DTOConvertOptions, EntityDataType, PersistentObject } from "../entities/entityManager";
import { deepCopy2DTO, DTORecord } from "../helpers/pojo";
import { arrayIdGet, arrayIdSet } from "../helpers/utilities";
import { kindCategoryRaw, markPublic } from "../services/allKinds";
import { RawCategory } from "../services/rawTypes";
import { RoomType } from "./RoomType";

export class RoomCategory implements PersistentObject<RoomCategory, RawCategory> {
  id: string = uuid();
  name: string = "";
  roomTypes: RoomType[] = [];

  constructor(name?: string) {
    makeObservable(this, {
      name: observable,
      roomTypes: observable,

      isBedroom: computed,
      isBathroom: computed,

      attributes: computed,
      fromDTO: action,
      addRoomType: action,
      removeRoomType: action,
    });

    if (name) this.name = name;
  }

  // TODO: correct implementation of function needed!!
  get isBedroom(): boolean {
    return this.name.toLowerCase().includes("bedroom");
  }

  // TODO: correct implementation of function needed!!
  get isGarage(): boolean {
    return this.name.toLowerCase().includes("garage");
  }
  get isShaft(): boolean {
    const roomTypeFunction = (this.roomTypes[0]?.settings?.function as string) ?? "";
    return roomTypeFunction.toLowerCase().includes("shaft");
  }
  // TODO: correct implementation of function needed!!
  get isOutdoor(): boolean {
    return this.name.toLowerCase().includes("outdoor");
  }

  // TODO: correct implementation of function needed!!
  get isBathroom(): boolean {
    return this.name.toLowerCase().includes("bathroom");
  }

  // TODO: correct implementation of function needed!!
  get isStairs(): boolean {
    return this.name.toLowerCase().includes("stairs");
  }

  // TODO: correct implementation of function needed!!
  get isKitchen(): boolean {
    return this.name.toLowerCase().includes("kitchen");
  }

  // TODO: correct implementation of function needed!!
  get isStorage(): boolean {
    return this.name.toLowerCase().includes("storage");
  }

  get visibleRoomTypesByFunction(): Map<string, RoomType[]> {
    return this.roomTypes.reduce((map, roomType) => {
      if (roomType.mark < markPublic.id) {
        return map;
      }

      const roomTypeFunction = (roomType?.settings?.function as string) || "-";

      if (map.has(roomTypeFunction)) {
        map.get(roomTypeFunction).push(roomType);
      } else {
        map.set(roomTypeFunction, [roomType]);
      }

      return map;
    }, new Map<string, RoomType[]>());
  }

  addRoomType(roomType: RoomType): void {
    roomType.roomCategoryId = this.id;
    arrayIdSet(this.roomTypes, roomType);
  }

  removeRoomType(roomTypeId: string) {
    const index = this.roomTypes.findIndex(el => el.id == roomTypeId);
    if (index < 0) {
      log.error("removeRoomType", `RoomType ${roomTypeId} not found`);
      return;
    }

    this.roomTypes.splice(index, 1);
  }

  // * UI properties *

  protected _attributes: DTORecord;
  get attributes(): DTORecord {
    if (!this._attributes) this._attributes = observable({});
    return this._attributes;
  }

  set attributes(attr: DTORecord) {
    Object.entries(attr || {}).forEach(([prop, val]) => {
      this.attributes[prop] = val;
    });
  }

  forKind: number;
  sortNum: number;
  ext_info?: DTORecord;

  // * Persistence part *

  static readonly kind = kindCategoryRaw;

  toDTO(format?: EntityDataType, options?: DTOConvertOptions): RawCategory {
    return deepCopy2DTO(
      {
        id: this.id,
        name: this.name,
        kind: RoomCategory.kind.id,
        attributes: this._attributes,
        forKind: this.forKind,
        sortNum: this.sortNum,
        ext_info: this.ext_info,
        attached: options?.scope === "full" ? this.roomTypes : undefined,
      },
      { scope: options?.scope, only: options?.onlyProps }
    ) as unknown as RawCategory;
  }

  fromDTO(arg: any, format: EntityDataType = "APIdata"): RoomCategory {
    if (format === "FormData") {
      throw "RoomCategory.fromDTO: FormData is not implementation";
    } else if (format.startsWith("APIdata")) {
      this.fromAPI(arg as RawCategory, format);
    } else throw `Unknown format ${format}`;

    return this;
  }

  protected fromAPI(dto: RawCategory, format: EntityDataType = "APIdata") {
    if (RoomCategory.kind.id != dto?.kind) throw "dto: RawCategory: incorrect kind";
    this.id = dto.id;
    this.name = dto.name;
    this.ext_info = dto.ext_info;
    if (format === "APIdata") {
      this.forKind = dto.forKind;
      this.sortNum = dto.sortNum;
      this.attributes = dto.attributes;
    }

    for (const roomType of dto.attached || []) {
      if (roomType.kind == RoomType.kind.id) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.addRoomType((arrayIdGet(this.roomTypes, roomType.id) || new RoomType()).fromDTO(roomType, format));
      } else throw "Category.attached item kind is unknown";
    }
  }
}
