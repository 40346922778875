import { observer } from "mobx-react";
import { Tooltip } from "antd";

import { appModel } from "../../../models/AppModel";

import "./Tooltip.sass";

export interface ITooltipOptions {
  show: boolean;
  top?: number;
  left?: number;
  text?: string;
}

const CustomTooltip = observer(() => {
  const options = appModel.tooltipOptions;
  if (!options || !options.show) {
    return null;
  }

  const getAdjustedPosition = () => {
    const tooltipHeight = 40;
    const offset = 10;
    const canvasHeight = window.innerHeight;

    let adjustedTop = options.top ?? 0;
    const adjustedLeft = options.left ?? 0;

    if (adjustedTop + tooltipHeight > canvasHeight) {
      adjustedTop = canvasHeight - tooltipHeight - offset;
    }

    return { top: adjustedTop, left: adjustedLeft };
  };

  const { top, left } = getAdjustedPosition();

  return (
    <Tooltip open={options.show} placement="bottom" title={options.text ?? ""} autoAdjustOverflow={false}>
      <div
        style={{
          left: `${left + 5}px`,
          top: `${top + 10}px`,
        }}
        className="tooltip-container"
      />
    </Tooltip>
  );
});

export default CustomTooltip;
