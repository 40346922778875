export enum WallType {
  EXT = "EXT",
  INT = "INT",
  GRG = "GRG",
  DDL = "DDL",
  PLM = "PLM",
  SFT = "SFT", // Shaft
  KNE = "KNE", // Knee
  SHW = "SHW", // Bath/Shower
}

export enum FuncCode {
  EXT_2X4_BATH = "EXT_2X4_BATH",
  EXT_2X4 = "EXT_2X4",
  EXT_2X4_SHFT = "EXT_2X4_SHFT",
  EXT_2X6 = "EXT_2X6",
  EXT_2X6_SHFT = "EXT_2X6_SHFT",
  INT_2X4_BATH = "INT_2X4_BATH",
  INT_2X4_GARG = "INT_2X4_GARG",
  EXT_2X4_GARG = "EXT_2X4_GARG",
  INT_2X4 = "INT_2X4",
  INT_2X4_KNEE = "INT_2X4_KNEE",
  INT_2X4_KNEE_OS = "INT_2X4_KNEE_OS",
  INT_2X4_SHFT = "INT_2X4_SHFT",
  INT_2X4_BATH_SHFT = "INT_2X4_BATH_SHFT",
  INT_2X4_GARG_SHFT = "INT_2X4_GARG_SHFT",
  EXT_2X4_GARG_SHFT = "EXT_2X4_GARG_SHFT",
  INT_2X4_SHFT_OS = "INT_2X4_SHFT-OS",
  INT_2X6_BATH = "INT_2X6_BATH",
  INT_2X6_GARG = "INT_2X6_GARG",
  EXT_2X6_GARG = "EXT_2X6_GARG",
  INT_2X6 = "INT_2X6",
  INT_2X6_SHFT_OS = "INT_2X6_SHFT-OS",
}

export enum WallLabel {
  EXT = "Exterior",
  INT = "Interior",
  SHW = "Wet",
  SFT = "Shaft",
  GRG = "Garage",
  KNE = "Knee",
}

export enum LayerType {
  EXTERIOR = "EXTERIOR",
  INTERIOR = "INTERIOR",
  CORE = "CORE",
}

export interface Layer {
  thickness: number;
  type: LayerType;
}

export interface RevitWall {
  layers: Layer[];
  func_code: FuncCode;
  frame_type: string;
  frame_size: string;
}

export interface Wall {
  interiorThickness: number;
  exteriorThickness: number;
  coreThickness: number;
  initialInteriorThickness?: number;
  initialExteriorThickness?: number;
  initialCoreThickness?: number;
  frameType: string;
  frameSize: string;
}
