import React, { useContext } from "react";
import { basicLogger, LDSingleKindContext, withLDProvider } from "launchdarkly-react-client-sdk";
import AuthContext from "./store/auth-context";
import App from "./App";

// The wrapped application component for LaunchDarkly provider
const WrappedApp = () => {
  const authCtx = useContext(AuthContext);

  const userContext: LDSingleKindContext = {
    kind: "user",
    key: authCtx.user?.id || "anonymous", // Unique identifier for the user
    name: authCtx.user?.username || "anonymous", // Optional: user name/email
  };

  // Wrap the App component with LaunchDarkly provider
  const LDWrappedApp = withLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID,
    context: userContext,
    timeout: 5, // recommended
    options: {
      //bootstrap: "localStorage",
      streaming: true,
      logger: basicLogger({ level: "error" }),
    },
  })(App);

  return <LDWrappedApp />;
};

export default WrappedApp;
