import * as THREE from "three";
import { Segment } from "./Segment";

export enum StrSegmentType {
  Exterior = "Exterior",
  Stacked = "Stacked",
  NonStacked = "NonStacked",
  Beam = "Beam",
  FloorSpanBeam = "FloorSpanBeam",
}

export class StrSegment extends Segment {
  public isUsed: boolean;
  public isOnEdge: boolean;
  public type?: StrSegmentType;

  constructor(
    start: THREE.Vector2,
    end: THREE.Vector2,
    type?: StrSegmentType,
    roomId: string | string[] = "",
    hasWall: boolean = false,
    isUsed = false,
    isOnEdge = false,
    extras: { [key: string]: any } = {}
  ) {
    super(start, end, roomId, hasWall, extras);
    this.type = type;
    this.isUsed = isUsed;
    this.isOnEdge = isOnEdge;
  }

  public clone(): StrSegment {
    return new StrSegment(
      this.start.clone(),
      this.end.clone(),
      this.type,
      this.roomId,
      this.hasWall,
      this.isUsed,
      this.isOnEdge,
      Object.assign({}, this.extras)
    );
  }

  static fromSegment(segment: Segment, type: StrSegmentType = null) {
    return new StrSegment(segment.start, segment.end, type, segment.roomId, segment.hasWall);
  }
}
