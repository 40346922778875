import wooListRoomType from "../woo_data/roomTypes";
import wooListCategory from "../woo_data/categories";
import wooListCorePlan from "../woo_data/corePlans";
import { Envelope, MetaHeader } from "./Envelope";
import { RawEntity } from "./rawTypes";
import { ErrorKindsEnum, MessageKindsEnum, showToastMessage } from "../helpers/messages";
import { ToastOptions } from "react-toastify";
import { sendSingle, takeCollection } from "./api";
import apiProvider from "./api/utilities/Provider";
import { asSingle, isEmpty } from "../helpers/pojo";
import {
  kindCategoryRaw,
  kindFloorRaw,
  kindCorePlanRaw,
  KindRecordRaw,
  kindRoomEntityRaw,
  kindRoomRaw,
  kindRoomTypeRaw,
  searchKindByID,
  kindVariationRaw,
} from "./allKinds";
import { isWooMode } from "../helpers/utilities";
import { registerUserAction } from "../store/UserDepot";
import log from "loglevel";

export interface ServiceArguments {
  collection?: string;
  opts?: any;
  kind?: KindRecordRaw;
}

export interface APIoptions {
  command?: "save";
  header?: MetaHeader;
  data?: RawEntity[];
  where?: any;
  url?: string;
  envelope?: Envelope;
  imageRelType?: "background" | "thumbnail";
  multiPartAppend?: { append(name: string, value: string | Blob, fileName?: string): void };
  handler?: (env: Envelope) => void;
}

export const ERROR_DATA_RESULT = [{ kind: 0 }];

export const receiveList = async (args: ServiceArguments, opts?: APIoptions): Promise<RawEntity[]> => {
  const collection = args.collection || args.kind?.table;

  if (isWooMode()) {
    if (collection === "categories") return wooListCategory;
    if (collection === "room_types") return wooListRoomType;
    if (collection === "corePlans") return wooListCorePlan;

    return ERROR_DATA_RESULT;
  }

  if (collection) {
    const envelope = await takeCollection(collection, opts);
    handlerMessagesFromInfo(envelope);
    return (envelope?.data as RawEntity[]) || ERROR_DATA_RESULT;
  }

  return ERROR_DATA_RESULT;
};

const abilitySendKind = [
  kindCorePlanRaw.id,
  kindRoomTypeRaw.id,
  kindFloorRaw.id,
  kindRoomRaw.id,
  kindRoomEntityRaw.id,
  kindCategoryRaw.id,
  kindVariationRaw.id,
];
export const deleteSingle = async (object: RawEntity, opts?: APIoptions): Promise<RawEntity> => {
  if (isWooMode()) {
    return asSingle(ERROR_DATA_RESULT);
  }

  const apiOptions = Object.assign({ url: searchKindByID(object.kind).table }, opts || {});

  const envelope = await apiProvider.remove("" + apiOptions.url, object.id);
  handlerMessagesFromInfo(envelope);
  return envelope.hasErrors ? null : asSingle(envelope.data);
};

export const saveSingle = async (object: RawEntity, opts?: APIoptions, showToast = true): Promise<{ result: RawEntity; notFound: boolean }> => {
  if (isWooMode()) {
    return { result: asSingle(ERROR_DATA_RESULT), notFound: false };
  }

  const apiOptions = Object.assign({ url: searchKindByID(object.kind).table }, opts || {});

  if (object.kind === kindCorePlanRaw.id) apiOptions.imageRelType = "background";
  else if (object.kind === kindRoomTypeRaw.id) apiOptions.imageRelType = "thumbnail";

  if (abilitySendKind.indexOf(object.kind) > -1) {
    // TODO: put options.scope to VAPI
    const envelope = await sendSingle(object, apiOptions);
    if (envelope === null) return null;
    const notFound = (envelope as any).response?.status === 404;
    if (notFound) {
      return { result: null, notFound: true };
    }
    throwErrorIDFromInfo(envelope);

    handlerMessagesFromInfo(envelope, showToast);

    if (envelope.hasErrors) return null;
    if (opts && envelope.info?.length) {
      opts.data = envelope.info as RawEntity[];
    }
    return { result: asSingle(envelope.data as RawEntity[]), notFound: false };
  }

  return { result: asSingle(ERROR_DATA_RESULT), notFound: false };
};

export async function pullDxfFile(fileId: string): Promise<any> {
  return apiProvider.getSingle("files", fileId);
}

// * * * Show messages from Envelop.info
export type ErrorReason = {
  code: string; // 'not found',
  name: string; // 'ErrorID',
  kind: number; // 1200,
  value: string; // '01cf74ec-d52c-11ed-b1ac-1f221693142a'
};

type ServMessageType = {
  name?: string;
  description?: string;
  text?: string;
  options?: ToastOptions;
  code?: string;
  kind: number;
  err?: ErrorReason;
};

const allErrorKindIDs: { [key: number]: boolean } = {};
Object.values(ErrorKindsEnum).forEach(id => (allErrorKindIDs[id] = true));

const allMessageKindIDs: { [key: number]: boolean } = {};
Object.values(MessageKindsEnum).forEach(id => (allMessageKindIDs[id] = true));

const checkMessageKind = (msg: ServMessageType) => allMessageKindIDs[msg.kind];
const checkNotMessageKind = (msg: ServMessageType) => !checkMessageKind(msg);

function throwErrorIDFromInfo(envelope: Envelope) {
  const infos: ServMessageType[] = envelope?.info;

  for (const message of infos || []) {
    if (message.err) {
      const { name, kind } = message.err;

      if (name === "ErrorID") {
        throw message.err;
      }
    }
  }
}

export function handlerMessagesFromInfo(envelope: Envelope, showToast = true): void {
  const infos: ServMessageType[] = envelope?.info;

  if (isEmpty(infos)) return;

  if (infos.some(message => allErrorKindIDs[message.kind])) {
    envelope.hasErrors = true;
  }

  envelope.info = infos.filter(checkNotMessageKind) as unknown as RawEntity[];

  setTimeout(
    (messages: ServMessageType[]) => {
      for (const servMessage of messages) {
        let result = "";
        if (servMessage.name) result = servMessage.name + "; ";

        result += servMessage.text || servMessage.description || "";

        if (servMessage.code) result = servMessage.code + ": " + result;

        if (showToast) {
          showToastMessage(servMessage.kind, result, servMessage.options);
        }
      }
    },
    32,
    infos.filter(checkMessageKind)
  );
}

registerUserAction("login", async () => {
  if (isWooMode()) {
    log.info("VAPI", "WOO mode");
    return;
  }
  // apiProvider.getAll('meta', (response) => {
  //   /*response = handleResponse(response);
  //   log.info("VAPI",response?.meta?.vapi);*/
  // });
});
