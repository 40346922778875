import IntervalTree from "node-interval-tree";
import * as THREE from "three";
import { settings } from "../../entities/settings";
import { appModel } from "../../models/AppModel";
import { RoomEntityType } from "../../models/RoomEntityType";
import { EPSILON, WALL_RENDER_ORDER } from "../consts";
import { GraphManager } from "../models/GraphManager";
import { SceneEntityType } from "../models/SceneEntityType";
import { Segment } from "../models/segments/Segment";
import { MergeSegmentsMode } from "../models/SegmentsMergeMode";
import GeometryUtils from "./GeometryUtils";
import MathUtils from "./MathUtils";
import SceneUtils from "./SceneUtils";
import UnitsUtils from "./UnitsUtils";
import SegmentsUtils from "./SegmentsUtils";
import { FuncCode, WallType } from "../../entities/catalogSettings/types";
import { catalogSettings } from "../../entities/catalogSettings";
import { WebAppUISettingsKeys } from "../../entities/settings/types";

export class WallAnalysisUtils {
  public static getKeyToEdgeFromGraph<T>(strGraph: GraphManager<T>): Map<string, Edge<T>[]> {
    const keyToEdges = new Map<string, Edge<T>[]>();
    strGraph.getEdges().forEach(edge => {
      const key = edge.keyStr;

      if (keyToEdges.has(key)) {
        keyToEdges.get(key).push(edge);
      } else {
        keyToEdges.set(key, [edge]);
      }
    });
    return keyToEdges;
  }

  public static regenerateRoomsWalls(soFloor: THREE.Object3D): void {
    const soRooms = soFloor.children;

    // Dispose of existing synthetic walls in all rooms
    soRooms.forEach(soRoom => this.disposeRoomWalls(soRoom));

    // Collect all wall segments
    const { externalSegments, internalSegments } = this.collectSegments(soRooms);

    appModel.baseManager.roomManager.updateSegmentsCache();
    const floorSegments = appModel.baseManager.roomManager.getFloorSegments(soFloor.userData.id); // Retrieve the wall segments for the current floor.
    // Process segments with walls (both external and internal)
    externalSegments.concat(internalSegments).forEach(segment => {
      if (segment.hasWall) {
        this.processAndCreateWallSegments(segment, floorSegments, soRooms, segment);
      }
    });

    // Process external segments without walls
    externalSegments.forEach(segment => {
      if (!segment.hasWall) {
        this.createAndPositionWall(segment, floorSegments, soRooms, segment);
      }
    });
  }

  /**
   * Processes a wall segment by subtracting overlapping opening lines (doors and windows)
   * and creating the corresponding wall segments for the remaining parts.
   *
   * @param {Segment} segment - The wall segment to process.
   * @param {any} floorSegments - The categorized floor segments to determine wall type and intersections.
   * @param {THREE.Object3D[]} soRooms - An array of room objects to find overlapping openings.
   */
  private static processAndCreateWallSegments = (segment: Segment, floorSegments: any, soRooms: THREE.Object3D[], nativeSegment?: Segment) => {
    const overlappingOpeningLines = this.collectOverlappingOpeningLines(segment, soRooms);

    // Subtract overlapping opening lines from the segment
    const remainingSegments = this.subtractOverlappingSegments(segment, overlappingOpeningLines);

    // Create and position walls for the remaining segments
    remainingSegments.forEach(remainingSegment => {
      this.createAndPositionWall(remainingSegment, floorSegments, soRooms, nativeSegment);
    });
  };

  /**
   * Collects all overlapping opening lines (doors and windows) for the given segment.
   *
   * @param {Segment} segment - The wall segment to check for overlaps.
   * @param {THREE.Object3D[]} soRooms - An array of room objects to search for openings.
   * @returns {Segment[]} An array of segments representing the overlapping opening lines.
   */
  private static collectOverlappingOpeningLines = (segment: Segment, soRooms: THREE.Object3D[]): Segment[] => {
    const overlappingOpeningLines: Segment[] = [];

    // Iterate over all rooms to collect overlapping opening lines
    soRooms.forEach(soRoom => {
      const openingWallLines = SceneUtils.getNetOpeningModelLine(soRoom);

      soRoom.children
        .filter(child => child.userData.type === RoomEntityType.Door || child.userData.type === RoomEntityType.Window)
        .forEach(opn => {
          const opnBb = GeometryUtils.getGeometryBoundingBox2D(opn);
          const wallLine = openingWallLines.get(opn.userData.id);
          if (!wallLine) return;

          const openingLine = SceneUtils.getOpeningProjectionOnWallLine(opnBb, wallLine);
          const openingLineSegment = new Segment(
            new THREE.Vector2(openingLine.start.x, openingLine.start.y),
            new THREE.Vector2(openingLine.end.x, openingLine.end.y)
          );

          // Check if the opening line overlaps with the segment
          if (openingLineSegment.overlapsSegment(segment)) {
            overlappingOpeningLines.push(openingLineSegment);
          }
        });
    });

    return overlappingOpeningLines;
  };

  /**
   * Subtracts overlapping segments (such as openings) from the main wall segment.
   *
   * @param {Segment} segment - The main wall segment.
   * @param {Segment[]} overlappingLines - The segments representing the overlapping openings.
   * @returns {Segment[]} The remaining parts of the wall segment after subtraction.
   */
  private static subtractOverlappingSegments = (segment: Segment, overlappingLines: Segment[]): Segment[] => {
    let remainingSegments = [segment];

    overlappingLines.forEach(overlappingLine => {
      const newRemainingSegments: Segment[] = [];
      remainingSegments.forEach(remainingSegment => {
        if (remainingSegment.overlapsSegment(overlappingLine)) {
          const subtractedParts = remainingSegment.subtractSegment(overlappingLine, segment.isHorizontal());
          newRemainingSegments.push(...subtractedParts);
        } else {
          newRemainingSegments.push(remainingSegment);
        }
      });
      remainingSegments = newRemainingSegments;
    });

    return remainingSegments;
  };

  /**
   * Calculates the maximum wall half size for the start or end point of a segment.
   *
   * @param {Segment} segment - The segment along which the wall is being created.
   * @param {any} floorSegments - The categorized floor segments to determine wall type and intersections.
   * @param {0 | 1} getEndPoint - 0 for start point, 1 for end point.
   * @param {THREE.Object3D[]} soRooms - An array of room objects to find the relevant intersected rooms.
   * @returns {number} - The maximum calculated wall half size.
   */
  public static getWallHalfSize(
    segment: Segment,
    floorSegments: any,
    getEndPoint: 0 | 1, // Restricting to only 0 or 1
    soRooms: THREE.Object3D[]
  ): number {
    // Determine whether we are calculating for the start or end point
    const point = getEndPoint === 0 ? segment.start : segment.end;
    const directionVector =
      getEndPoint === 0
        ? new THREE.Vector2(segment.start.x, segment.start.y).sub(segment.end).normalize()
        : new THREE.Vector2(segment.end.x, segment.end.y).sub(segment.start).normalize();

    // Get perpendicular segments
    const perpendicularSegments = this.getPerpendicularSegmentsByWallType(segment, floorSegments);
    const intersectSegments: { [key: string]: Segment[] } = {};

    // Find intersecting segments at the start or end point
    for (const type in perpendicularSegments) {
      intersectSegments[type] = perpendicularSegments[type].filter(
        (s: Segment) => GeometryUtils.areVectors2Equal(s.start, point) || GeometryUtils.areVectors2Equal(s.end, point)
      );
    }

    // Calculate the wall half size based on the intersection segments and return the maximum value
    const wallHalfSizes = this.calculateWallHalfSize(intersectSegments, segment, directionVector, soRooms);
    return Math.max(...wallHalfSizes);
  }

  /**
   * Creates and positions a wall along a given segment within a room.
   * Determines the wall type based on floor segments, calculates intersection points with
   * perpendicular segments, adjusts segment size, applies necessary transformations, and
   * positions the wall accordingly.
   *
   * @param {Segment} segment - The segment along which the synthetic wall will be created.
   * @param {any} floorSegments - The categorized floor segments to determine wall type and intersections.
   * @param {THREE.Object3D[]} soRooms - An array of room objects to find the relevant intersected rooms.
   */

  private static createAndPositionWall = (segment: Segment, floorSegments: any, soRooms: THREE.Object3D[], nativeSegment?: Segment) => {
    const soRoom = soRooms.find(soRoom => soRoom.userData.id === segment.roomId[0]);
    if (!soRoom) return;

    const wallType = this.determineWallTypeBySegmentOverlap(segment, floorSegments);
    if (!wallType) return;

    // Get the maximum wall half sizes for start and end points
    const startWallHalfSize = this.getWallHalfSize(segment, floorSegments, 0, soRooms);
    const endWallHalfSize = this.getWallHalfSize(segment, floorSegments, 1, soRooms);

    let wallInteriorThickness = catalogSettings.walls[wallType].interiorThickness;
    let wallExteriorThickness = catalogSettings.walls[wallType].exteriorThickness;
    if (!appModel.showFinishFaceDimension) {
      wallInteriorThickness = catalogSettings.walls[wallType].coreThickness;
      wallExteriorThickness = catalogSettings.walls[wallType].coreThickness;
    }

    // Adjust segment size based on wall half sizes
    if (segment.isHorizontal()) {
      segment.start.x -= startWallHalfSize;
      segment.end.x += endWallHalfSize;
    } else {
      segment.start.y -= startWallHalfSize;
      segment.end.y += endWallHalfSize;
    }

    // Apply transformations and position the wall
    this.applySegmentTransformations(segment, soRoom);

    const wallPosition = this.calculateWallPosition(segment, wallExteriorThickness, wallInteriorThickness);

    const size = (wallInteriorThickness + wallExteriorThickness) / 2;
    const wallBBox = new THREE.Box3(new THREE.Vector3(-size, -size, 0), new THREE.Vector3(size, size, 0));

    const normal = segment.isHorizontal() ? new THREE.Vector3(1, 0, 0) : new THREE.Vector3(0, 1, 0);
    const plane = new THREE.Plane(normal, 0);

    const wall = GeometryUtils.deepClone(this.createSyntheticWallBlock(wallBBox, nativeSegment));
    SceneUtils.stretchRoomEntity(wall, plane, -2 * size + segment.length());

    wall.position.copy(segment.getCenter3().add(wallPosition));

    soRoom.add(wall);
  };

  /**
   * Disposes of all walls within a room by removing them from the room object
   * and properly disposing of their associated geometry and resources.
   * @param {THREE.Object3D} soRoom - The room object containing synthetic wall entities.
   */
  private static disposeRoomWalls = (soRoom: THREE.Object3D) => {
    const walls = soRoom.children.filter(child => child.userData.type === SceneEntityType.SyntheticWall);
    if (walls.length) {
      soRoom.remove(...walls);
      walls.forEach(wall => GeometryUtils.disposeObject(wall));
    }
  };

  /**
   * Applies matrix transformations (translation, rotation, and scaling) to a segment
   * based on the position, rotation, and scale of the room object.
   * @param {Segment} segment - The segment to be transformed.
   * @param {THREE.Object3D} soRoom - The room object whose transformations will be applied to the segment.
   */
  private static applySegmentTransformations = (segment: Segment, soRoom: THREE.Object3D) => {
    const mtr = new THREE.Matrix3().translate(-soRoom.position.x, -soRoom.position.y);
    mtr.premultiply(new THREE.Matrix3().rotate(soRoom.rotation.z));
    mtr.premultiply(new THREE.Matrix3().scale(1 / soRoom.scale.x, 1 / soRoom.scale.y));
    segment.applyMatrix3(mtr);
  };

  /**
   * Retrieves perpendicular segments for different wall types based on the given segment.
   * Dynamically extracts wall types from the floorSegments object.
   *
   * @param {Segment} segment - The wall segment to check.
   * @param {any} floorSegments - The floor segments object containing different wall types.
   * @returns {object} - An object containing perpendicular segments for each wall type.
   */
  private static getPerpendicularSegmentsByWallType(segment: Segment, floorSegments: any): any {
    // Dynamically get the wall types by extracting keys from the floorSegments object
    const wallTypes = Object.keys(floorSegments);

    // Use reduce to build an object with perpendicular segments for each wall type
    return wallTypes.reduce(
      (result, type) => {
        result[type] = segment.getPerpendicularSegments(floorSegments[type]);
        return result;
      },
      {} as Record<string, any>
    );
  }

  /**
   * Calculates the wall position based on the segment's orientation and relative position.
   * @param {Segment} segment - The wall segment.
   * @param {number} wallExteriorThickness - The thickness of the exterior wall.
   * @param {number} wallInteriorThickness - The thickness of the interior wall.
   * @returns {THREE.Vector3} - The calculated wall position in 3D space.
   */
  private static calculateWallPosition(segment: Segment, wallExteriorThickness: number, wallInteriorThickness: number): THREE.Vector3 {
    const offset = (wallExteriorThickness - wallInteriorThickness) / 2;

    const determinePositionOffset = (startCoord: number, axis: "x" | "y"): THREE.Vector3 => {
      const isCloserToNegative = startCoord < 0;
      const positionOffset = isCloserToNegative ? -offset : offset;

      return axis === "x" ? new THREE.Vector3(positionOffset, 0, 0) : new THREE.Vector3(0, positionOffset, 0);
    };

    if (segment.isHorizontal()) {
      return determinePositionOffset(segment.start.y, "y");
    } else {
      return determinePositionOffset(segment.start.x, "x");
    }
  }

  /**
   * Determines the wall type based on the segment's overlap with floor segment categories.
   * @param {Segment} segment - The segment to check.
   * @param {any} floorSegments - The floor segments categorized by type.
   * @returns {WallType | null} - The WallType, or null if not applicable.
   */
  private static determineWallTypeBySegmentOverlap(segment: Segment, floorSegments: any): FuncCode | null {
    const grgSegment = floorSegments.grg.find(s => segment.overlapsSegment(s));

    if (grgSegment) {
      return FuncCode[grgSegment.functionCode] ?? FuncCode.INT_2X4_GARG;
    }

    // const ddlSegment = floorSegments.ddl.find(s => segment.overlapsSegment(s));

    // if (ddlSegment) {
    //   return FuncCode[ddlSegment.functionCode];
    // }

    const internalSegment = floorSegments.internal.find(s => segment.overlapsSegment(s));

    if (internalSegment) {
      return FuncCode[internalSegment.functionCode] ?? FuncCode.INT_2X4;
    }

    const externalSegment = floorSegments.external.find(s => segment.overlapsSegment(s));

    if (externalSegment) {
      return FuncCode[externalSegment.functionCode] ?? FuncCode.EXT_2X4;
    }

    return null; // Return null if no specific wall type is found.
  }

  private static calculateWallHalfSize(
    intersectSegments: { [key: string]: Segment[] },
    segment: Segment,
    directionVector: THREE.Vector2,
    soRooms: THREE.Object3D[]
  ): number[] {
    const wallHalfSize: number[] = [0];

    for (const [type, segments] of Object.entries(intersectSegments)) {
      (segments as Segment[]).forEach((intersectSegment: Segment) => {
        const wallType = FuncCode[intersectSegment.functionCode];
        if (!wallType) return;

        const segmentSoRoom = soRooms.find(soRoom => soRoom.userData.id === intersectSegment.roomId[0]);
        const roomBb = SceneUtils.getRoomBoundingBoxByModelLines(segmentSoRoom);
        const center = roomBb.getCenter(new THREE.Vector3());

        let perpendicularSegmentDirection;
        if (segment.isHorizontal()) {
          perpendicularSegmentDirection = new THREE.Vector2(intersectSegment.getCenter3().x, center.y).sub(new THREE.Vector2(center.x, center.y)).normalize();
        } else {
          perpendicularSegmentDirection = new THREE.Vector2(center.x, intersectSegment.getCenter3().y).sub(new THREE.Vector2(center.x, center.y)).normalize();
        }

        // in case of 'Core Face based' toggled - use core thickness
        if (!appModel.showFinishFaceDimension) {
          wallHalfSize.push(catalogSettings.walls[wallType].coreThickness);
        } else {
          if (GeometryUtils.areVectors2Equal(directionVector, perpendicularSegmentDirection)) {
            wallHalfSize.push(catalogSettings.walls[wallType].exteriorThickness);
          } else {
            wallHalfSize.push(catalogSettings.walls[wallType].interiorThickness);
          }
        }
      });
    }

    return wallHalfSize;
  }

  public static mergeSegments<T extends Segment>(segments: T[], mergeSegmentsMode: MergeSegmentsMode): T[] {
    if (mergeSegmentsMode === MergeSegmentsMode.Disabled) {
      return segments;
    }

    const vertical: T[] = [];
    const horizontal: T[] = [];

    segments.forEach(segment => {
      if (segment.isHorizontal()) {
        const mergedSegment = horizontal.find(
          ms =>
            ms.hasWall === segment.hasWall &&
            (mergeSegmentsMode === MergeSegmentsMode.All || (mergeSegmentsMode === MergeSegmentsMode.SameRoom && ms.roomId[0] === segment.roomId[0])) &&
            MathUtils.areNumbersEqual(ms.start.y, segment.start.y) &&
            (MathUtils.areNumbersEqual(ms.start.x, segment.end.x) || MathUtils.areNumbersEqual(ms.end.x, segment.start.x))
        );

        if (!mergedSegment) {
          horizontal.push(segment);
        } else if (MathUtils.areNumbersEqual(mergedSegment.start.x, segment.end.x)) {
          mergedSegment.start = segment.start;
        } else {
          mergedSegment.end = segment.end;
        }
      } else {
        const mergedSegment = vertical.find(
          ms =>
            ms.hasWall === segment.hasWall &&
            (mergeSegmentsMode === MergeSegmentsMode.All || (mergeSegmentsMode === MergeSegmentsMode.SameRoom && ms.roomId[0] === segment.roomId[0])) &&
            MathUtils.areNumbersEqual(ms.start.x, segment.start.x) &&
            (MathUtils.areNumbersEqual(ms.start.y, segment.end.y) || MathUtils.areNumbersEqual(ms.end.y, segment.start.y))
        );

        if (!mergedSegment) {
          vertical.push(segment);
        } else if (MathUtils.areNumbersEqual(mergedSegment.start.y, segment.end.y)) {
          mergedSegment.start = segment.start;
        } else {
          mergedSegment.end = segment.end;
        }
      }
    });

    return [...vertical, ...horizontal];
  }
  public static collectSegments(
    soRooms: THREE.Object3D[],
    mergeSegmentsMode = MergeSegmentsMode.SameRoom,
    excludeOutdoorRooms = true
  ): { externalSegments: Segment[]; internalSegments: Segment[] } {
    if (excludeOutdoorRooms) {
      soRooms = soRooms.filter(soRoom => appModel.getRoomType(soRoom.userData.roomTypeId).attributes.indoor);
    }
    const graph = WallAnalysisUtils.prepareGraphForAnalysis(soRooms);

    return WallAnalysisUtils.extractInternalExternalWallSegments(graph, mergeSegmentsMode, soRooms);
  }

  public static getSegmentsWithContourOffsets(
    soRooms: THREE.Object3D[],
    removeOpenings = true,
    excludeOutdoorRooms = true
  ): { externalSegments: Segment[]; blockedExternalSegments: Segment[]; internalSegments: Segment[] } {
    if (excludeOutdoorRooms) {
      soRooms = soRooms.filter(soRoom => appModel.getRoomType(soRoom.userData.roomTypeId).attributes.indoor);
    }
    const graph = WallAnalysisUtils.prepareGraphForAnalysis(soRooms);

    const segments = WallAnalysisUtils.collectSegmentsWithContourCornerOffset(graph, MergeSegmentsMode.SameRoom);
    if (!removeOpenings) {
      return segments;
    }

    const offset = settings.values.validationSettings.openingStructuralTolerance;
    const openings = SceneUtils.getRoomsOpeningsAsLines(soRooms)
      .map(opening => {
        const direction = new THREE.Vector2().subVectors(opening.end, opening.start).normalize();
        opening.start.addScaledVector(direction, -offset);
        opening.end.addScaledVector(direction, offset);
        const isHorizontal = GeometryUtils.isLineHorizontal(opening);
        const axis1 = isHorizontal ? "x" : "y";
        const axis2 = isHorizontal ? "y" : "x";
        const wallSegment = segments.externalSegments.find(
          lowerSegment =>
            isHorizontal === lowerSegment.isHorizontal() &&
            MathUtils.areNumbersEqual(opening.start[axis2], lowerSegment.end[axis2]) &&
            opening.end[axis1] >= lowerSegment.start[axis1] &&
            lowerSegment.end[axis1] >= opening.start[axis1]
        );

        if (!wallSegment) {
          return null;
        }
        if (opening.start[axis1] < wallSegment.start[axis1]) {
          opening.start[axis1] = wallSegment.start[axis1];
        }
        if (opening.end[axis1] > wallSegment.end[axis1]) {
          opening.end[axis1] = wallSegment.end[axis1];
        }

        segments.blockedExternalSegments.push(opening);
        return opening;
      })
      .filter(o => !!o);

    return {
      externalSegments: segments.externalSegments.flatMap(wall => SegmentsUtils.getNotOverlappedSegmentParts(wall, openings)),
      blockedExternalSegments: segments.blockedExternalSegments,
      internalSegments: segments.internalSegments,
    };
  }
  public static collectSegmentsFromBoxes(bbs: THREE.Box3[]): { externalSegments: Segment[]; internalSegments: Segment[] } {
    const data = WallAnalysisUtils.prepareSegmentsForAnalysis(bbs);

    const graph = new GraphManager<Segment>();

    const sweepIsoX = new SweepLine(data.horizontalWallSegments, graph, true);
    data.xSites.forEach(site => sweepIsoX.moveTo(site));

    const sweepIsoY = new SweepLine(data.verticalWallSegments, graph, false);
    data.ySites.forEach(site => sweepIsoY.moveTo(site));

    return WallAnalysisUtils.extractInternalExternalWallSegments(graph, MergeSegmentsMode.All);
  }

  public static findRoomInnerContour(roomBb: THREE.Box3, bbs: THREE.Box3[]): Segment[] {
    bbs.push(roomBb);
    const data = WallAnalysisUtils.prepareSegmentsForAnalysis(bbs);

    const graph = new GraphManager<Segment>();

    const sweepIsoX = new SweepLine(data.horizontalWallSegments, graph, true);
    data.xSites.forEach(site => sweepIsoX.moveTo(site));

    const sweepIsoY = new SweepLine(data.verticalWallSegments, graph, false);
    data.ySites.forEach(site => sweepIsoY.moveTo(site));

    // extract segments and find start segment
    const segments = [];
    const checkedSegments: Set<string> = new Set();
    const center = roomBb.getCenter(new THREE.Vector3());
    let startSegment: Segment;

    graph.getEdges().forEach(edge => {
      const key = edge.keyStr;
      const segment = edge.data;

      if (!checkedSegments.has(key)) {
        checkedSegments.add(key);
        segments.push(segment);

        if (!segment.isHorizontal() || segment.start.y > center.y || segment.end.x < center.x || segment.start.x > center.x) {
          return;
        }

        if (!startSegment || segment.start.y > startSegment.start.y) {
          startSegment = segment;
        }
      }
    });
    segments.splice(segments.indexOf(startSegment), 1);

    //build contour
    const innerContour: Segment[] = [startSegment];

    while (!GeometryUtils.areVectors2Equal(innerContour[0].start, innerContour[innerContour.length - 1].end)) {
      const lastSegment = innerContour[innerContour.length - 1];

      const linkedSegments = segments.filter(
        segment => GeometryUtils.areVectors2Equal(lastSegment.end, segment.start) || GeometryUtils.areVectors2Equal(lastSegment.end, segment.end)
      );
      linkedSegments.forEach(segment => {
        if (GeometryUtils.areVectors2Equal(lastSegment.end, segment.end)) {
          segment.revert();
        }
      });

      if (!linkedSegments.length) {
        return [];
      }

      let nextSegment: Segment = linkedSegments[0];

      if (linkedSegments.length > 1) {
        // get left segment
        const last = lastSegment.delta().normalize();

        linkedSegments.forEach(segment => {
          if (last.cross(segment.delta().normalize()) > last.cross(nextSegment.delta().normalize())) {
            nextSegment = segment;
          }
        });
      }

      segments.splice(segments.indexOf(nextSegment), 1);
      innerContour.push(nextSegment);
    }

    return innerContour;
  }

  public static prepareGraphForAnalysis(soRooms: THREE.Object3D[]): GraphManager<Segment> {
    const data = WallAnalysisUtils.prepareWallSegmentsForAnalysis(soRooms);

    const graph = new GraphManager<Segment>();

    const sweepIsoX = new SweepLine(data.horizontalWallSegments, graph, true);
    data.xSites.forEach(site => sweepIsoX.moveTo(site));

    const sweepIsoY = new SweepLine(data.verticalWallSegments, graph, false);
    data.ySites.forEach(site => sweepIsoY.moveTo(site));
    return graph;
  }

  private static prepareSegmentsForAnalysis(bbs: THREE.Box3[]): {
    xSites: number[];
    ySites: number[];
    horizontalWallSegments: IntervalTree<Segment, number>;
    verticalWallSegments: IntervalTree<Segment, number>;
  } {
    const horizontalWallSegments = new IntervalTree<Segment, number>();
    const verticalWallSegments = new IntervalTree<Segment, number>();
    const xSites: number[] = [];
    const ySites: number[] = [];

    // The algorithm assumes that all segments are oriented from bottom to top or from left to right
    for (const bb of bbs) {
      //  * ---->-----*
      //  /\         /\
      //  |          |
      //  *---->-----*

      // Put horizontal segments
      const top = new Segment(new THREE.Vector2(bb.min.x, bb.max.y), new THREE.Vector2(bb.max.x, bb.max.y));
      horizontalWallSegments.insert(bb.min.x, bb.max.x, top);

      const bottom = new Segment(new THREE.Vector2(bb.min.x, bb.min.y), new THREE.Vector2(bb.max.x, bb.min.y));
      horizontalWallSegments.insert(bb.min.x, bb.max.x, bottom);

      xSites.push(bb.min.x);
      xSites.push(bb.max.x);

      // Put vertical segments
      const left = new Segment(new THREE.Vector2(bb.min.x, bb.min.y), new THREE.Vector2(bb.min.x, bb.max.y));
      verticalWallSegments.insert(bb.min.y, bb.max.y, left);

      const right = new Segment(new THREE.Vector2(bb.max.x, bb.min.y), new THREE.Vector2(bb.max.x, bb.max.y));
      verticalWallSegments.insert(bb.min.y, bb.max.y, right);

      ySites.push(bb.min.y);
      ySites.push(bb.max.y);
    }

    return {
      xSites: GeometryUtils.deduplicateSites(xSites),
      ySites: GeometryUtils.deduplicateSites(ySites),
      horizontalWallSegments,
      verticalWallSegments,
    };
  }

  private static prepareWallSegmentsForAnalysis(soRooms: THREE.Object3D[]): {
    xSites: number[];
    ySites: number[];
    horizontalWallSegments: IntervalTree<Segment, number>;
    verticalWallSegments: IntervalTree<Segment, number>;
  } {
    const horizontalWallSegments = new IntervalTree<Segment, number>();
    const verticalWallSegments = new IntervalTree<Segment, number>();
    const xSites: number[] = [];
    const ySites: number[] = [];

    // The algorithm assumes that all segments are oriented from bottom to top or from left to right
    for (const soRoom of soRooms) {
      //  * ---->-----*
      //  /\         /\
      //  |          |
      //  *---->-----*

      const rect = SceneUtils.getRoomBoundingBoxByModelLines(soRoom);
      const wallBbs = soRoom.children.filter(child => child.userData.type === RoomEntityType.Wall).map(wall => GeometryUtils.getGeometryBoundingBox2D(wall));

      // Put horizontal segments
      const top = new Segment(new THREE.Vector2(rect.min.x, rect.max.y), new THREE.Vector2(rect.max.x, rect.max.y), soRoom.userData.id);
      let midPoint = top.getCenter3();
      top.hasWall = wallBbs.some(bb => bb.containsPoint(midPoint));
      horizontalWallSegments.insert(rect.min.x, rect.max.x, top);

      const bottom = new Segment(new THREE.Vector2(rect.min.x, rect.min.y), new THREE.Vector2(rect.max.x, rect.min.y), soRoom.userData.id);
      midPoint = bottom.getCenter3();
      bottom.hasWall = wallBbs.some(bb => bb.containsPoint(midPoint));
      horizontalWallSegments.insert(rect.min.x, rect.max.x, bottom);

      xSites.push(rect.min.x);
      xSites.push(rect.max.x);

      // Put vertical segments
      const left = new Segment(new THREE.Vector2(rect.min.x, rect.min.y), new THREE.Vector2(rect.min.x, rect.max.y), soRoom.userData.id);
      midPoint = left.getCenter3();
      left.hasWall = wallBbs.some(bb => bb.containsPoint(midPoint));
      verticalWallSegments.insert(rect.min.y, rect.max.y, left);

      const right = new Segment(new THREE.Vector2(rect.max.x, rect.min.y), new THREE.Vector2(rect.max.x, rect.max.y), soRoom.userData.id);
      midPoint = right.getCenter3();
      right.hasWall = wallBbs.some(bb => bb.containsPoint(midPoint));
      verticalWallSegments.insert(rect.min.y, rect.max.y, right);

      ySites.push(rect.min.y);
      ySites.push(rect.max.y);
    }

    return {
      xSites: GeometryUtils.deduplicateSites(xSites),
      ySites: GeometryUtils.deduplicateSites(ySites),
      horizontalWallSegments,
      verticalWallSegments,
    };
  }

  private static collectSegmentsWithContourCornerOffset(
    graph: GraphManager<Segment>,
    mergeSegmentsMode: MergeSegmentsMode
  ): { externalSegments: Segment[]; blockedExternalSegments: Segment[]; internalSegments: Segment[] } {
    const keyToEdges = WallAnalysisUtils.getKeyToEdgeFromGraph(graph);

    let externalSegments: Segment[] = [];
    const internalSegments: Segment[] = [];
    const offsetSegments: Segment[] = [];
    const onlyWallsGraph = new GraphManager<Segment>();

    keyToEdges.forEach(edges => {
      if (edges.length > 1) {
        const segment = edges[0].data;
        segment.hasWall = edges.some(e => e.data.hasWall);
        internalSegments.push(segment.clone());
        if (segment.hasWall) {
          // To differentiate from external wall edges
          segment.hasWall = false;
          onlyWallsGraph.createEdgeFromPoints(segment.start, segment.end, segment);
        }
      } else {
        edges[0].data.hasWall = true;
        externalSegments.push(edges[0].data);
      }
    });
    externalSegments = WallAnalysisUtils.mergeSegments(externalSegments, mergeSegmentsMode);
    externalSegments.forEach(es => onlyWallsGraph.createEdgeFromPoints(es.start, es.end, es));
    const offset = settings.values.validationSettings.edgeOfExteriorWallTolerance + UnitsUtils.getSyntheticWallHalfSize();

    onlyWallsGraph.getVertices().forEach(vertex => {
      const linked = onlyWallsGraph.getLinkedEdges(vertex);
      if (linked.length > 1 && WallAnalysisUtils.edgesHaveCorner(linked)) {
        linked.forEach(edge => {
          // If vertices were flipped before, it means that the segment is already too small.
          if (!edge.data.hasWall || !edge.data.isAxisAligned()) {
            return;
          }
          const direction = new THREE.Vector2().subVectors(edge.data.end, edge.data.start).normalize();
          const axis = edge.data.isHorizontal() ? "x" : "y";
          if (MathUtils.areNumbersEqual(vertex.x, edge.data.start.x) && MathUtils.areNumbersEqual(vertex.y, edge.data.start.y)) {
            const prevPointPosition = edge.data.start.clone();
            edge.data.start.addScaledVector(direction, offset);
            const newEndPosition = edge.data.start.clone();
            if (newEndPosition[axis] > edge.data.end[axis]) {
              newEndPosition[axis] = edge.data.end[axis];
            }
            const offsetSegment = new Segment(prevPointPosition, newEndPosition, edge.data.roomId[0]);

            offsetSegments.push(offsetSegment);
          } else {
            const prevPointPosition = edge.data.end.clone();
            edge.data.end.addScaledVector(direction, -offset);
            const newStartPosition = edge.data.end.clone();
            if (newStartPosition[axis] < edge.data.start[axis]) {
              newStartPosition[axis] = edge.data.start[axis];
            }
            offsetSegments.push(new Segment(newStartPosition, prevPointPosition, edge.data.roomId[0]));
          }
        });
      }
    });

    // If added offset was bigger than segment length, vertices were flipped i.e. segment was too small.
    return {
      externalSegments: externalSegments.filter(es => es.isAxisAligned()),
      blockedExternalSegments: offsetSegments,
      internalSegments,
    };
  }

  private static edgesHaveCorner(edges: Edge<Segment>[]) {
    const isHorizontal = edges[0].data.isHorizontal();
    return edges.slice(1, edges.length).some(e => e.data.isHorizontal() !== isHorizontal);
  }

  private static extractInternalExternalWallSegments(
    graph: GraphManager<Segment>,
    mergeSegmentsMode: MergeSegmentsMode,
    soRooms?: THREE.Object3D[]
  ): { externalSegments: Segment[]; internalSegments: Segment[] } {
    const keyToEdges = WallAnalysisUtils.getKeyToEdgeFromGraph(graph);
    // const clonedMap = new Map(Array.from(keyToEdges.entries()).map(([key, edges]) => [key, edges.slice()]));
    const externalSegments: Segment[] = [];
    const internalSegments: Segment[] = [];

    keyToEdges.forEach(edges => {
      const segment = edges[0].data;

      if (edges.length > 1) {
        segment.hasWall = edges.some(e => e.data.hasWall);
        segment.roomId.unshift(edges[1].data.roomId[0]);

        internalSegments.push(segment);
        segment.addClassification(WallType.INT);
      } else {
        externalSegments.push(segment);
        segment.addClassification(WallType.EXT);
      }

      for (const roomId of segment.roomId) {
        const soRoom = soRooms.find(soRoom => soRoom.userData.id === roomId);

        if (soRoom) {
          const roomType = appModel.getRoomType(soRoom.userData.roomTypeId);
          if (!roomType) {
            continue;
          }

          const roomCategory = appModel.getRoomCategory(roomType.roomCategoryId);

          if (roomCategory.isGarage) {
            segment.addClassification(WallType.GRG);
          }
          if (roomCategory.isShaft) {
            segment.addClassification(WallType.SFT);
          }
        }
      }
    });

    return {
      externalSegments: WallAnalysisUtils.mergeSegments(externalSegments, mergeSegmentsMode),
      internalSegments: WallAnalysisUtils.mergeSegments(internalSegments, mergeSegmentsMode),
    };
  }
  private static createSyntheticWallBlock(bBox: THREE.Box3, segment?: Segment): THREE.Mesh {
    const result = GeometryUtils.createFilledPlane(bBox, {
      color: settings.getColorNumber(WebAppUISettingsKeys.wallsColor),
      transparent: true,
      opacity: 1,
    });
    result.userData.type = SceneEntityType.SyntheticWall;
    result.userData.segment = segment;
    result.renderOrder = WALL_RENDER_ORDER;

    return result;
  }
}

class SweepLine {
  private currPosition: number = NaN;
  private currSegments: Segment[] = [];

  private segmentsTree: IntervalTree<Segment, number>;
  private graph: GraphManager<Segment>;
  private sweepX: boolean; // If TRUE - vertical sweep line will be moved from left to right. otherwise - horizontal from bottom to top

  constructor(segmentTree: IntervalTree<Segment, number>, graph: GraphManager<Segment>, sweepX: boolean) {
    this.segmentsTree = segmentTree;
    this.graph = graph;
    this.sweepX = sweepX;
  }

  moveTo(nextPos: number): void {
    this.currPosition = nextPos;

    const leftSegments: Segment[] = [];
    const rightSegments: Segment[] = [];

    this.currSegments.forEach(segment => {
      const segEndPos = this.sweepX ? segment.end.x : segment.end.y;

      if (MathUtils.areNumbersEqual(segEndPos, this.currPosition)) {
        leftSegments.push(segment);
      } else {
        const splitPoint = this.intersectSegment(segment);
        const left = new Segment(segment.start, splitPoint.clone(), segment.roomId[0], segment.hasWall);
        leftSegments.push(left);

        const right = new Segment(splitPoint.clone(), segment.end, segment.roomId[0], segment.hasWall);
        rightSegments.push(right);
      }
    });

    // Process all left segments, as they will not be affected by next iteration
    this.processLeftSegments(leftSegments);

    // Collect right segment for current position
    const segmentsAtPosition = this.segmentsTree.search(nextPos - EPSILON, nextPos + EPSILON);

    // /*if( _dbg)*/ {
    //   const segmentOnSweepLine = segmentsAtPosition.filter((seg: Line2d) => {
    //     return this.isOnSweepLine(seg.start) && this.isOnSweepLine(seg.end);
    //   });
    //   if (segmentOnSweepLine.length > 0) throw new Error("Unsupported segments");
    // }

    // Keep only segments that start at current positions. Other segments at the position should have been already in this.currSegments from previous iteration
    const newRightSegments = segmentsAtPosition.filter((seg: Segment) => {
      return this.isOnSweepLine(seg.start);
    });
    rightSegments.push(...newRightSegments);

    // when we move to next site, the right segment will become current.
    this.currSegments = rightSegments;
  }

  private isOnSweepLine(point: THREE.Vector2): boolean {
    if (this.sweepX) {
      return MathUtils.areNumbersEqual(point.x, this.currPosition);
    }

    return MathUtils.areNumbersEqual(point.y, this.currPosition);
  }

  private intersectSegment(seg: Segment): THREE.Vector2 {
    let t: number;
    if (this.sweepX) {
      t = (this.currPosition - seg.start.x) / (seg.end.x - seg.start.x);
    } else {
      t = (this.currPosition - seg.start.y) / (seg.end.y - seg.start.y);
    }

    // /*if(_debug)*/ {
    //   if (t < 0 || t > 1) throw Error("Invalid sites for sweep line");
    // }
    return seg.evaluate(t);
  }

  private processLeftSegments(leftSegments: Segment[]): void {
    leftSegments.forEach(segment => {
      this.graph.createEdgeFromNumbers(segment.start.x, segment.start.y, segment.end.x, segment.end.y, segment);
    });
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
// Graph
////////////////////////////////////////////////////////////////////////////////////////////////////////
class Vertex {
  id: number;
  x: number;
  y: number;

  constructor(id: number, x: number, y: number) {
    this.x = x;
    this.y = y;
    this.id = id;
  }
}

// Undirected edge
class Edge<T> {
  v1: Vertex;
  v2: Vertex;
  data: T;

  constructor(v1: Vertex, v2: Vertex, data: T) {
    if (v1.id < v2.id) {
      this.v1 = v1;
      this.v2 = v2;
    } else {
      this.v1 = v2;
      this.v2 = v1;
    }
    this.data = data;
  }

  get keyStr(): string {
    return "[" + this.v1.id + ", " + this.v2.id + "]";
  }
}
