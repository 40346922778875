import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { appModel } from "../../models/AppModel";
import { NotFound } from "../components/common/NotFound";
import LoadingSpinner from "../components/common/LoadingSpinner";
import { useHistory } from "react-router-dom";

import EditorHeader from "../components/Editor/EditorHeader";
import Editor from "../components/Editor/Editor";
import "../components/Editor/Editor.sass";
import "../components/common/LoadingSpinner.sass";
import { useFlags } from "launchdarkly-react-client-sdk";

const EditorPage = observer(() => {
  const { corePlanId = "" } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const flags = useFlags();

  useEffect(() => {
    if (appModel.corePlans.length && corePlanId) {
      // sets active corePlan by it's id from url
      const corePlan = appModel.corePlans.find(prj => prj.id === corePlanId);

      if (!corePlan) {
        history.push("/"); // Redirect to the home page if no corePlan
      }

      appModel.setActiveCorePlan(corePlan).then(res => {
        // wait all data loaded and corePlan being set
        setIsLoading(false);
      });
    }
  }, [appModel.corePlans.length, corePlanId]);

  useEffect(() => {
    appModel.featureFlags = flags;
  }, [flags]);

  return (
    <>
      {isLoading ? (
        <div className="spinner-container">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="editor-page">
          <EditorHeader />
          <Editor />
        </div>
      )}
    </>
  );
});

export default EditorPage;
