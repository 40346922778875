import { catalogSettings } from "../../../../../entities/catalogSettings";
import { WallLabel, WallType } from "../../../../../entities/catalogSettings/types";
import { formatToInches } from "../../../../../helpers/utilities";
import { appModel } from "../../../../../models/AppModel";

import "./WallProperties.sass";

interface ItemProps {
  title: string;
  value?: string;
}

const wallPropertiesConfig: Record<string, { label: string; errorMessage: string }> = {
  Classification: { label: "Classification", errorMessage: "Unknown wall class" },
  "Core Type": { label: "Core Type", errorMessage: "Cannot retrieve data" },
  "Total Thickness": { label: "Total Thickness", errorMessage: "Cannot retrieve data" },
};

const WallProperties = () => {
  const firstSelectedRoomWall = appModel.selectedRoomWall.values().next().value;
  const wallSegment = firstSelectedRoomWall?.userData?.segment;
  const classification =
    wallSegment?.classification.map(cl => {
      return WallLabel[cl];
    }) ?? "";

  const classificationKey = firstSelectedRoomWall.userData?.segment.functionCode;
  const coreType = `${catalogSettings.walls[classificationKey].frameType} ${catalogSettings.walls[classificationKey].frameSize}` ?? "";
  const thicknessSum =
    catalogSettings.walls[classificationKey].initialInteriorThickness +
      catalogSettings.walls[classificationKey].initialCoreThickness +
      catalogSettings.walls[classificationKey].initialExteriorThickness ?? "";

  const properties = [
    { title: "Classification", value: classification.join(", ") },
    { title: "Core Type", value: coreType },
    { title: "Total Thickness", value: formatToInches(thicknessSum) },
  ];

  const Item = ({ title, value }: ItemProps) => {
    const { errorMessage } = wallPropertiesConfig[title];
    return (
      <div className="item">
        <span>{title}</span>
        <span>{value || <span className="error">{errorMessage}</span>}</span>
      </div>
    );
  };

  return (
    <div className="wall-properties">
      <span className="title">Selected Wall</span>
      <div className="properties">
        {properties.map(property => (
          <Item key={property.title} title={property.title} value={property.value} />
        ))}
      </div>
    </div>
  );
};

export default WallProperties;
