import { action, makeObservable, observable } from "mobx";
import { RoomEntityType } from "./RoomEntityType";
import RoomOpeningManager from "../editor/managers/RoomManager/RoomOpeningManager";

export default class RoomOpening {
  public id: string;
  public type: RoomEntityType;
  public roomId: string = null;
  public shiftDistance: number = 0;
  public referenceShiftDistance: number = 0;
  public isLocked: boolean = false;

  constructor(id: string, type: RoomEntityType) {
    this.id = id;
    this.type = type;

    makeObservable(this, {
      shiftDistance: observable,
      referenceShiftDistance: observable,
      isLocked: observable,

      setShiftDistance: action,
      setReferenceShiftDistance: action,
      setIsLocked: action,
    });
  }

  public setShiftDistance(value: number): void {
    this.shiftDistance = value;
  }
  public setReferenceShiftDistance(value: number): void {
    this.referenceShiftDistance = value;
  }
  public setIsLocked(value: boolean): void {
    this.isLocked = value;
  }
}
