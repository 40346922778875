import { showToastMessage } from "../../helpers/messages";

export class WallFunctionRules {
  public rules: any[] = [];

  public async loadFunctionRules() {
    try {
      const wallFunctionRulesJson = await fetch("/settings/wallFunctionRules.json").then(data => data.json());
      this.rules = wallFunctionRulesJson.rules;
    } catch (error) {
      showToastMessage("Error", "Error occurred on wall function rules loading.");
    }
  }
}

const wallFunctionRules = new WallFunctionRules();

export default wallFunctionRules;
