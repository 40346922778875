import { Input } from "antd";
import { useState } from "react";
import { appModel } from "../../../../models/AppModel";
import { settings } from "../../../../entities/settings";

const initialValue = !Number.isNaN(appModel.gridUnitSizeInches) ? appModel.gridUnitSizeInches : settings.values.validationSettings.gridCellSizeForNewCorePlans;

const GridOptions = () => {
  const [value, setValue] = useState(initialValue);

  const onGridUnitSizeClick = (e: any) => {
    e.stopPropagation();
  };

  const onGridUnitSizeChanged = (e: any) => {
    const { value } = e.target;

    if (!Number.isNaN(value)) {
      updateValue(value);
    }
  };

  const onBlur = (e: any) => {
    const parsedValue = Number.parseFloat(e.target.value);
    const value = !Number.isNaN(parsedValue) ? parsedValue : settings.values.validationSettings.gridCellSizeForNewCorePlans;

    updateValue(value);
  };

  const updateValue = value => {
    setValue(value);
    appModel.setGridUnitSizeInches(value);
  };

  return (
    <div>
      Grid step size, inches
      <br />
      <Input
        data-testid="grid-step-size-input"
        value={value}
        defaultValue={settings.values.validationSettings.gridCellSizeForNewCorePlans}
        onClick={onGridUnitSizeClick}
        onChange={onGridUnitSizeChanged}
        onBlur={onBlur}
        style={{ width: "80px", minWidth: "0", padding: "0 4px", margin: "4px 0", fontSize: "12px" }}
        disabled={appModel.isViewOnlyMode}
      />
    </div>
  );
};

export default GridOptions;
