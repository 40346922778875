import IntervalTree from "node-interval-tree";
import * as THREE from "three";
import { settings } from "../../../entities/settings";
import { appModel } from "../../../models/AppModel";
import { Side } from "../../../models/Side";
import { EPSILON } from "../../consts";
import RoomManager from "../../managers/RoomManager/RoomManager";
import { Direction } from "../../models/Direction";
import { FloorSpace, FloorSpaceTree, Space } from "../../models/FloorSpaceTree";
import { Edge, GraphManager } from "../../models/GraphManager";
import { MergeSegmentsMode } from "../../models/SegmentsMergeMode";
import { IGravityLoadsValidationLegend, IGravityLoadsValidationResult } from "../../models/ValidationResult";
import { Segment } from "../../models/segments/Segment";
import { StrSegment, StrSegmentType } from "../../models/segments/StrSegment";
import GeometryUtils from "../../utils/GeometryUtils";
import MathUtils from "../../utils/MathUtils";
import SceneUtils from "../../utils/SceneUtils";
import SegmentsUtils from "../../utils/SegmentsUtils";
import UnitsUtils from "../../utils/UnitsUtils";
import { WallAnalysisUtils } from "../../utils/WallAnalysisUtils";
import { IValidationTool } from "./IValidationTool";
import { WebAppUISettingsKeys } from "../../../entities/settings/types";

export default class GravityLoadsValidationTool implements IValidationTool {
  private validationResult: Map<string, IGravityLoadsValidationResult> = new Map<string, IGravityLoadsValidationResult>();
  private validationLegend: IGravityLoadsValidationLegend = null;
  private stackedWalls: Map<string, StrSegment[]> = new Map<string, StrSegment[]>();
  private nonStackedWalls: Map<string, StrSegment[]> = new Map<string, StrSegment[]>();
  private beams: Map<string, StrSegment[]> = new Map<string, StrSegment[]>();
  private maxCantileverFailedItemsIds: Map<string, string[]> = new Map();
  private maxSpanFailedItemsIds: Map<string, string[]> = new Map();

  constructor(private roomManager: RoomManager) {}

  public performValidation(full = true) {
    this.resetResult();

    const soFloors = [...appModel.activeCorePlan.floors].sort((a, b) => b.index - a.index).map(f => this.roomManager.getSoFloor(f.id));
    const externalSegmentsMap = new Map<string, Segment[]>();
    const internalSegmentsMap = new Map<string, Segment[]>();

    soFloors.forEach(soFloor => {
      const { externalSegments, internalSegments } = WallAnalysisUtils.collectSegments(soFloor.children, MergeSegmentsMode.All);
      externalSegmentsMap.set(soFloor.userData.id, externalSegments);
      internalSegmentsMap.set(soFloor.userData.id, internalSegments);
    });

    this.stackedWallsCheck(externalSegmentsMap, internalSegmentsMap, full);
    if (full) {
      this.maxSpanCheck();
      this.maxCantileverCheck();
      this.setResult();
    }
  }

  public visualizeValidationResult(container: THREE.Group, floorId: string) {
    const thickness = UnitsUtils.getSyntheticWallHalfSize();
    this.stackedWalls.get(floorId).forEach(r => {
      container.add(
        SceneUtils.createSegmentPlane(
          r,
          thickness,
          settings.getColorNumber(r.type === StrSegmentType.Exterior ? WebAppUISettingsKeys.gravityExteriorLBColor : WebAppUISettingsKeys.gravityStackedLBColor)
        )
      );
    });
    this.nonStackedWalls.get(floorId).forEach(r => {
      container.add(SceneUtils.createSegmentPlane(r, thickness, settings.getColorNumber(WebAppUISettingsKeys.gravityNonLBColor)));
    });
    this.beams.get(floorId).forEach(beam => {
      if (beam.length() - EPSILON > UnitsUtils.getMaxBeamLength()) {
        container.add(SceneUtils.createSegmentDashedPlane(beam, settings.getColorNumber(WebAppUISettingsKeys.gravityBeamColor)));
      } else {
        container.add(SceneUtils.createSegmentPlane(beam, thickness, settings.getColorNumber(WebAppUISettingsKeys.gravityBeamColor)));
      }
    });

    [...this.maxCantileverFailedItemsIds.get(floorId), ...this.maxSpanFailedItemsIds.get(floorId)].forEach(roomId => {
      const soRoom = this.roomManager.getCorePlanSoRoom(roomId);
      if (!soRoom.userData.isIntersected) {
        SceneUtils.highlightIntersectedRoom(soRoom);
        soRoom.userData.isCantileverFailed = true;
      }
    });
  }

  public removeValidationVisualization(): void {
    [...Array.from(this.maxCantileverFailedItemsIds.values()), ...Array.from(this.maxSpanFailedItemsIds.values())].flat().forEach(roomId => {
      const soRoom = this.roomManager.getCorePlanSoRoom(roomId);
      if (soRoom?.userData.isCantileverFailed) {
        delete soRoom.userData.isCantileverFailed;
        SceneUtils.unhighlightIntersectedRoom(soRoom);
      }
    });
  }

  public getFloorValidationResult(floorId: string): IGravityLoadsValidationResult {
    const result = this.validationResult.get(floorId);
    result.legend = this.validationLegend;
    return result;
  }

  public getValidationResultErrors(floorId?: string) {
    if (floorId) {
      const result = this.validationResult.get(floorId);
      return [
        { name: "maxSpanCheckResult", result: !result.maxSpanCheckResult },
        { name: "maxCantileverCheckResult", result: !result.maxCantileverCheckResult },
        { name: "maxBeamLengthResult", result: !result.maxBeamLengthResult },
      ];
    }
    const values = [...this.validationResult.values()];
    return [
      { name: "maxSpanCheckResult", result: values.some(v => !v.maxSpanCheckResult) },
      { name: "maxCantileverCheckResult", result: values.some(v => !v.maxCantileverCheckResult) },
      { name: "maxBeamLengthResult", result: values.some(v => !v.maxBeamLengthResult) },
    ];
  }

  public resetResult() {
    this.validationResult.clear();
    this.stackedWalls.clear();
    this.nonStackedWalls.clear();
    this.beams.clear();
    this.maxCantileverFailedItemsIds.clear();
    this.maxSpanFailedItemsIds.clear();
    this.validationLegend = null;
  }

  public getSegmentsResult(): { stacked: Map<string, StrSegment[]>; nonStacked: Map<string, StrSegment[]>; beams: Map<string, StrSegment[]> } {
    const stacked = new Map<string, StrSegment[]>();
    this.stackedWalls.forEach((segments, floorId) => {
      stacked.set(floorId, SegmentsUtils.mergeUnsortedSegments(segments));
    });
    const nonStacked = new Map<string, StrSegment[]>();
    this.nonStackedWalls.forEach((segments, floorId) => {
      nonStacked.set(floorId, SegmentsUtils.mergeUnsortedSegments(segments));
    });

    return { stacked, nonStacked, beams: this.beams };
  }

  private setResult() {
    let totalExternalWallsLength = 0;
    let totalStackedWallsLength = 0;
    let totalNonStackedWallsLength = 0;
    let totalBeamsLength = 0;

    appModel.activeCorePlan.floors.forEach(f => {
      const [externalWallsLength, stackedWallsLength] = this.stackedWalls.get(f.id).reduce(
        (sum, seg) => {
          const index = seg.type === StrSegmentType.Exterior ? 0 : 1;
          sum[index] = sum[index] + seg.length();
          return sum;
        },
        [0, 0]
      );
      const nonStackedWallsLength = this.nonStackedWalls.get(f.id).reduce((sum, seg) => sum + seg.length(), 0);
      const beams = this.beams.get(f.id);
      const beamsLength = beams.reduce((sum, seg) => sum + seg.length(), 0);

      totalExternalWallsLength += externalWallsLength;
      totalStackedWallsLength += stackedWallsLength;
      totalNonStackedWallsLength += nonStackedWallsLength;
      totalBeamsLength += beamsLength;

      this.validationResult.set(f.id, {
        maxSpanCheckResult: this.maxSpanFailedItemsIds.get(f.id).length === 0,
        maxCantileverCheckResult: this.maxCantileverFailedItemsIds.get(f.id).length === 0,
        maxBeamLengthResult: !beams.some(beam => beam.length() - EPSILON > UnitsUtils.getMaxBeamLength()),
        presentSegmentTypes: [
          ...(externalWallsLength > 0 ? ["Exterior"] : []),
          ...(stackedWallsLength > 0 ? ["Stacked"] : []),
          ...(nonStackedWallsLength > 0 ? ["Non"] : []),
          ...(beams.some(beam => beam.length() - EPSILON <= UnitsUtils.getMaxBeamLength()) ? ["Beam"] : []),
          ...(beams.some(beam => beam.length() - EPSILON > UnitsUtils.getMaxBeamLength()) ? ["ImpossibleBeam"] : []),
        ],
      });
    });

    const totalWallLength = totalExternalWallsLength + totalStackedWallsLength + totalNonStackedWallsLength + totalBeamsLength;
    this.validationLegend = {
      totalWallLength,
      percentages: [
        { type: "Exterior", percent: getPercent(totalExternalWallsLength) },
        { type: "Stacked", percent: getPercent(totalStackedWallsLength) },
        { type: "Non", percent: getPercent(totalNonStackedWallsLength) },
        { type: "Beam", percent: getPercent(totalBeamsLength) },
      ].filter(f => f.percent > 0),
    };

    function getPercent(total: number) {
      return MathUtils.round((total / totalWallLength) * 100);
    }
  }

  public stackedWallsCheck(externalSegmentsMap: Map<string, Segment[]>, internalSegmentsMap: Map<string, Segment[]>, createSupportingBeams = true): void {
    // Sorted from top to bottom.
    const soFloors = [...appModel.activeCorePlan.floors].sort((a, b) => b.index - a.index).map(f => this.roomManager.getSoFloor(f.id));
    const internalWalls = new Map<string, StrSegment[]>();

    soFloors.forEach((soFloor, idx) => {
      const internalSegments = internalSegmentsMap.get(soFloor.userData.id);
      const externalSegments = externalSegmentsMap.get(soFloor.userData.id);
      this.stackedWalls.set(
        soFloor.userData.id,
        externalSegments.map(es => new StrSegment(es.start, es.end, StrSegmentType.Exterior, es.roomId, true))
      );
      // ground floor
      if (idx === soFloors.length - 1) {
        this.stackedWalls.get(soFloor.userData.id).push(...internalSegments.filter(s => s.hasWall).map(s => StrSegment.fromSegment(s, StrSegmentType.Stacked)));
        internalWalls.set(soFloor.userData.id, []);
      } else {
        internalWalls.set(
          soFloor.userData.id,
          internalSegments.filter(s => s.hasWall).map(s => StrSegment.fromSegment(s))
        );
      }
    });

    for (let i = 0; i < soFloors.length; i++) {
      const floorId = soFloors[i].userData.id;
      const lowerFloorId: string = soFloors[i + 1]?.userData.id;
      const upperFloorId: string = soFloors[i - 1]?.userData.id;

      this.nonStackedWalls.set(floorId, []);
      this.beams.set(floorId, []);

      if (lowerFloorId) {
        this.getStackedNonStackedWalls(floorId, lowerFloorId, internalWalls);
      } else {
        internalWalls.get(floorId).forEach(iw => (iw.type = StrSegmentType.NonStacked));
        this.nonStackedWalls.get(floorId).push(...internalWalls.get(floorId));
      }

      if (upperFloorId && createSupportingBeams) {
        const upperFloorStackedSegments = SegmentsUtils.mergeUnsortedSegments(this.stackedWalls.get(upperFloorId)) as StrSegment[];
        this.createSupportingBeams(floorId, upperFloorStackedSegments);
      }
    }
  }

  private getStackedNonStackedWalls(floorId: string, lowerFloorId: string, internalWalls: Map<string, StrSegment[]>) {
    const lowerInternalWalls = internalWalls.get(lowerFloorId);
    const lowerStackedWalls = this.stackedWalls.get(lowerFloorId);
    const lowerFloorWalls = [...lowerStackedWalls, ...lowerInternalWalls];
    const size = UnitsUtils.getSyntheticWallHalfSize();

    [...this.stackedWalls.get(floorId), ...internalWalls.get(floorId)].forEach((segment: StrSegment) => {
      const isHorizontal = segment.isHorizontal();
      const comp = isHorizontal ? "x" : "y";
      const otherComp = isHorizontal ? "y" : "x";
      const stacked: StrSegment[] = [];

      const lowerOnLineSegments = lowerFloorWalls
        .filter(
          lowerSegment =>
            isHorizontal === lowerSegment.isHorizontal() &&
            MathUtils.areNumbersEqual(segment.start[otherComp], lowerSegment.end[otherComp], UnitsUtils.getMaxWallDeviation()) &&
            segment.end[comp] >= lowerSegment.start[comp] &&
            lowerSegment.end[comp] >= segment.start[comp]
        )
        .sort((a, b) => a.start[comp] - b.start[comp]);

      for (const lowerSegment of lowerOnLineSegments) {
        if (lowerSegment.type && segment.type) {
          continue;
        }

        const overlap = [Math.max(segment.start[comp], lowerSegment.start[comp]), Math.min(segment.end[comp], lowerSegment.end[comp])];
        if (!segment.type) {
          const prevSegment =
            stacked.length && MathUtils.areNumbersEqual(lowerSegment.start[comp], stacked[stacked.length - 1].end[comp]) && stacked[stacked.length - 1];

          if (prevSegment) {
            prevSegment.end[comp] = overlap[1];
          } else {
            const newSegmentStart = isHorizontal ? new THREE.Vector2(overlap[0], segment.start.y) : new THREE.Vector2(segment.start.x, overlap[0]);
            const newSegmentEnd = isHorizontal ? new THREE.Vector2(overlap[1], segment.start.y) : new THREE.Vector2(segment.start.x, overlap[1]);
            const newSegment = new StrSegment(newSegmentStart, newSegmentEnd, StrSegmentType.Stacked, null, segment.hasWall);
            stacked.push(newSegment);
          }
        }

        if (!lowerSegment.type && overlap[1] - overlap[0] > 2 * size) {
          const newSegmentStart = isHorizontal ? new THREE.Vector2(overlap[0], segment.start.y) : new THREE.Vector2(segment.start.x, overlap[0]);
          const newSegmentEnd = isHorizontal ? new THREE.Vector2(overlap[1], segment.start.y) : new THREE.Vector2(segment.start.x, overlap[1]);
          const newSegment = new StrSegment(newSegmentStart, newSegmentEnd, StrSegmentType.Stacked, null, true);
          lowerStackedWalls.push(newSegment);

          // Modifying lower floor internal segments to remove already classified portion of the wall segment.
          if (MathUtils.areNumbersEqual(overlap[1] - overlap[0], lowerSegment.length())) {
            lowerInternalWalls.splice(lowerInternalWalls.indexOf(lowerSegment), 1);
          } else if (!MathUtils.areNumbersEqual(overlap[0], lowerSegment.start[comp]) && !MathUtils.areNumbersEqual(overlap[1], lowerSegment.end[comp])) {
            const newIntSegmentStart = isHorizontal ? new THREE.Vector2(overlap[1], lowerSegment.start.y) : new THREE.Vector2(lowerSegment.start.x, overlap[1]);
            const newIntSegmentEnd = isHorizontal
              ? new THREE.Vector2(lowerSegment.end.x, lowerSegment.start.y)
              : new THREE.Vector2(lowerSegment.start.x, lowerSegment.end.y);
            const newInternalSegment = new StrSegment(newIntSegmentStart, newIntSegmentEnd);
            lowerInternalWalls.push(newInternalSegment);
            lowerSegment.end[comp] = overlap[0];
          } else if (MathUtils.areNumbersEqual(overlap[0], lowerSegment.start[comp])) {
            lowerSegment.start[comp] = overlap[1];
          } else if (MathUtils.areNumbersEqual(overlap[1], lowerSegment.end[comp])) {
            lowerSegment.end[comp] = overlap[0];
          }
        }
      }

      if (!segment.type) {
        this.extractNonStackedSubsegments(
          floorId,
          segment,
          stacked.filter(s => s.length() > 2 * size)
        );
      }
    });
  }

  private createSupportingBeams(floorId: string, upperFloorStackedSegments: StrSegment[]) {
    const stackedWallTree: { x: IntervalTree<StrSegment, number>; y: IntervalTree<StrSegment, number> } = {
      x: new IntervalTree<StrSegment, number>(),
      y: new IntervalTree<StrSegment, number>(),
    };
    const stackedWalls: { x: StrSegment[]; y: StrSegment[] } = { x: [], y: [] };
    this.stackedWalls.get(floorId).forEach(wall => {
      const comp = wall.isHorizontal() ? "x" : "y";
      stackedWallTree[comp].insert(wall.start[comp], wall.end[comp], wall);
      stackedWalls[comp].push(wall);
    });

    upperFloorStackedSegments.forEach(upper => {
      const isHorizontal = upper.isHorizontal();

      const comp = isHorizontal ? "x" : "y";
      const comp2 = isHorizontal ? "y" : "x";
      const size = UnitsUtils.getSyntheticWallHalfSize();

      const onLineSegments = stackedWalls[comp].filter(wall =>
        MathUtils.areNumbersEqual(wall.start[comp2], upper.start[comp2], UnitsUtils.getMaxWallDeviation())
      );

      const overlappingSegments = onLineSegments
        .filter(wall => wall.end[comp] >= upper.start[comp] && wall.start[comp] <= upper.end[comp])
        .sort((a, b) => a.start[comp] - b.start[comp]);

      const newBeams = [];
      if (!overlappingSegments.length) {
        const newBeam = new StrSegment(upper.start.clone(), upper.end.clone(), StrSegmentType.Beam);
        newBeams.push(newBeam);
      } else {
        let lastStartValue = upper.start[comp];

        for (const overlapped of overlappingSegments) {
          if (lastStartValue < overlapped.start[comp] && overlapped.start[comp] - lastStartValue > 2 * size) {
            const newBeamStart = isHorizontal ? new THREE.Vector2(lastStartValue, upper.start.y) : new THREE.Vector2(upper.start.x, lastStartValue);
            const newBeamEnd = isHorizontal
              ? new THREE.Vector2(overlapped.start[comp], upper.start.y)
              : new THREE.Vector2(upper.start.x, overlapped.start[comp]);
            const newBeam = new StrSegment(newBeamStart, newBeamEnd, StrSegmentType.Beam);
            newBeams.push(newBeam);
          }
          lastStartValue = overlapped.end[comp];
        }

        if (lastStartValue < upper.end[comp] && upper.end[comp] - lastStartValue > 2 * size) {
          const newBeamStart = isHorizontal ? new THREE.Vector2(lastStartValue, upper.start.y) : new THREE.Vector2(upper.start.x, lastStartValue);
          const newBeamEnd = isHorizontal ? new THREE.Vector2(upper.end[comp], upper.start.y) : new THREE.Vector2(upper.start.x, upper.end[comp]);
          const newBeam = new StrSegment(newBeamStart, newBeamEnd, StrSegmentType.Beam);
          newBeams.push(newBeam);
        }
      }

      newBeams.forEach(beam => {
        const possibleWallEnds = stackedWallTree[comp2].search(beam.start[comp2] - EPSILON, beam.start[comp2] + EPSILON).map(s => s.start[comp]);
        const possibleOnLineEnds = onLineSegments.flatMap(s => [s.start[comp], s.end[comp]]);
        const possibleEnds = [...possibleWallEnds, ...possibleOnLineEnds];
        let nearestSmaller: number = Number.NEGATIVE_INFINITY;
        let nearestGreater: number = Number.POSITIVE_INFINITY;
        possibleEnds.forEach(position => {
          // Allowing punching wall less than wall width.
          if (
            (position <= beam.start[comp] && position > nearestSmaller) ||
            (position > beam.start[comp] && MathUtils.areNumbersEqual(position, beam.start[comp], 2 * size))
          ) {
            nearestSmaller = position;
          }
          if (
            (position >= beam.end[comp] && position < nearestGreater) ||
            (position < beam.end[comp] && MathUtils.areNumbersEqual(position, beam.end[comp], 2 * size))
          ) {
            nearestGreater = position;
          }
        });

        if (Number.isFinite(nearestSmaller)) {
          beam.start[comp] = nearestSmaller;
        }

        if (Number.isFinite(nearestGreater)) {
          beam.end[comp] = nearestGreater;
        }

        const supportingWallsCount = possibleWallEnds.filter(
          pos => MathUtils.isNumberLessOrEqual(beam.start[comp], pos) && MathUtils.isNumberLessOrEqual(pos, beam.end[comp])
        ).length;
        const supportingOnLineCount = possibleOnLineEnds.filter(
          pos => MathUtils.isNumberLessOrEqual(beam.start[comp], pos) && MathUtils.isNumberLessOrEqual(pos, beam.end[comp])
        ).length;

        if (supportingOnLineCount === 0 && supportingWallsCount < 2) {
          let nextNearestSmaller: number = Number.NEGATIVE_INFINITY;
          let nextNearestGreater: number = Number.POSITIVE_INFINITY;
          possibleEnds.forEach(position => {
            if (position < nearestSmaller && position > nextNearestSmaller) {
              nextNearestSmaller = position;
            }
            if (position > nearestGreater && position < nextNearestGreater) {
              nextNearestGreater = position;
            }
          });

          if (Number.isFinite(nextNearestSmaller)) {
            beam.start[comp] = nextNearestSmaller;
          } else if (Number.isFinite(nextNearestGreater)) {
            beam.end[comp] = nextNearestGreater;
          }
        }

        this.beams.get(floorId).push(beam);
      });
    });
  }

  private extractNonStackedSubsegments(floorId: string, segment: StrSegment, stackedSubsegments: StrSegment[]) {
    if (!stackedSubsegments.length) {
      const newSegment = new StrSegment(segment.start.clone(), segment.end.clone(), StrSegmentType.NonStacked, segment.roomId, true);
      this.nonStackedWalls.get(floorId).push(newSegment);
      return;
    }

    this.stackedWalls.get(floorId).push(...stackedSubsegments);

    const isHorizontal = segment.isHorizontal();
    const comp = isHorizontal ? "x" : "y";
    const sortedStackedSubsegments = stackedSubsegments.sort((a, b) => a.start[comp] - b.start[comp]);

    let lastStartValue = segment.start[comp];

    for (const subsegment of sortedStackedSubsegments) {
      if (!MathUtils.areNumbersEqual(subsegment.start[comp], lastStartValue)) {
        const nonStackedSegmentStart = isHorizontal ? new THREE.Vector2(lastStartValue, segment.start.y) : new THREE.Vector2(segment.start.x, lastStartValue);
        const nonStackedSegmentEnd = isHorizontal
          ? new THREE.Vector2(subsegment.start[comp], segment.start.y)
          : new THREE.Vector2(segment.start.x, subsegment.start[comp]);
        const nonStackedSegment = new StrSegment(nonStackedSegmentStart, nonStackedSegmentEnd, StrSegmentType.NonStacked, null, segment.hasWall);
        this.nonStackedWalls.get(floorId).push(nonStackedSegment);
      }
      lastStartValue = subsegment.end[comp];
    }

    if (!MathUtils.areNumbersEqual(lastStartValue, segment.end[comp])) {
      const nonStackedSegmentStart = isHorizontal ? new THREE.Vector2(lastStartValue, segment.start.y) : new THREE.Vector2(segment.start.x, lastStartValue);
      const nonStackedSegmentEnd = isHorizontal ? new THREE.Vector2(segment.end[comp], segment.start.y) : new THREE.Vector2(segment.start.x, segment.end[comp]);
      const nonStackedSegment = new StrSegment(nonStackedSegmentStart, nonStackedSegmentEnd, StrSegmentType.NonStacked, null, segment.hasWall);
      this.nonStackedWalls.get(floorId).push(nonStackedSegment);
    }
  }

  private extractSpaces(contour: Edge<StrSegment>[], graph: GraphManager<StrSegment>, visitedEdges: Set<string>): Space[] {
    const getStartEdge = () => {
      let horizontal: Edge<StrSegment>, vertical: Edge<StrSegment>;
      contour.forEach(edge => {
        if (edge.data.isHorizontal()) {
          if (!horizontal || horizontal.data.start.y > edge.data.start.y) {
            horizontal = edge;
          }
        } else {
          if (!vertical || vertical.data.start.x < edge.data.start.x) {
            vertical = edge;
          }
        }
      });

      return horizontal || vertical;
    };
    const spaces: Space[] = [];

    while (contour.length !== 0) {
      const path = SegmentsUtils.graphTraverse(getStartEdge(), graph, false, visitedEdges);

      spaces.push(
        new Space(
          path.contour.map(edge => edge.data.clone()),
          path.innerEdges.map(edge => edge.data.clone())
        )
      );

      path.contour.forEach((edge: Edge<StrSegment>) => {
        const idx = contour.indexOf(edge);
        if (idx !== -1) {
          contour.splice(idx, 1);
          visitedEdges.add(edge.keyStr);
        } else {
          edge.data.revert();
          contour.push(edge);
        }
      });
      path.innerEdges.forEach(edge => visitedEdges.add(edge.keyStr));
    }

    return spaces;
  }

  private buildFloorSpaceTree(segments: StrSegment[]): FloorSpaceTree {
    segments = SegmentsUtils.splitIntersectedSegments(segments.map(s => s.clone())) as StrSegment[];

    const floorSpaces: Space[] = [];
    const innerSegments: StrSegment[] = [];
    const graph = new GraphManager<StrSegment>();
    const visitedEdges = new Set<string>();

    segments.forEach(segment => graph.createEdgeFromPoints(segment.start, segment.end, segment));

    const edges = graph
      .getEdges()
      .slice()
      .sort((a: Edge<StrSegment>, b: Edge<StrSegment>) => {
        const aIsHorizontal = a.data.isHorizontal();
        const bIsHorizontal = b.data.isHorizontal();

        if (aIsHorizontal !== bIsHorizontal) {
          return aIsHorizontal ? -1 : 1;
        }

        if (aIsHorizontal) {
          return a.data.start.y - b.data.start.y;
        }

        return b.data.start.x - a.data.start.x;
      });

    edges.forEach(edge => {
      if (visitedEdges.has(edge.keyStr)) {
        return;
      }

      const path = SegmentsUtils.graphTraverse(edge, graph, true, visitedEdges);

      path.innerEdges.forEach((edge: Edge<StrSegment>) => {
        visitedEdges.add(edge.keyStr);
        innerSegments.push(edge.data.clone());
      });

      if (path.contour.length === 0) {
        return;
      }

      floorSpaces.push(...this.extractSpaces(path.contour as Edge<StrSegment>[], graph, visitedEdges));
    });

    const tree = new FloorSpaceTree();

    floorSpaces.forEach(space => {
      tree.insertSpace(space);
    });
    innerSegments.forEach(segment => {
      tree.insertInnerSegment(segment);
    });

    return tree;
  }

  private maxSpanCheck(): void {
    appModel.activeCorePlan.floors.forEach(floor => {
      this.maxSpanFailedItemsIds.set(floor.id, []);

      const stackedWalls = this.stackedWalls.get(floor.id);
      const nonStackedWalls = floor.index === 0 ? this.nonStackedWalls.get(floor.id) : [];
      const beams = this.beams.get(floor.id);
      const indoorSoRooms = floor.rooms
        .filter(room => appModel.getRoomType(room.roomTypeId).attributes.indoor)
        .map(room => this.roomManager.getCorePlanSoRoom(room.id));

      const horizontalStackedWalls = new IntervalTree<StrSegment, number>();
      const verticalStackedWalls = new IntervalTree<StrSegment, number>();
      stackedWalls.forEach(wall => {
        if (wall.isHorizontal()) {
          horizontalStackedWalls.insert(wall.start.x, wall.end.x, wall);
        } else {
          verticalStackedWalls.insert(wall.start.y, wall.end.y, wall);
        }
      });

      const roomBoxes: THREE.Box3[] = [];
      const roomBoxesMap: Map<string, THREE.Box3> = new Map();
      indoorSoRooms.forEach(soRoom => {
        const bb = SceneUtils.getRoomBoundingBoxByModelLines(soRoom);
        roomBoxesMap.set(soRoom.userData.id, bb);
        roomBoxes.push(bb);
      });

      const { internalSegments } = WallAnalysisUtils.collectSegments(indoorSoRooms);
      const possibleSplitters = this.collectBeams(internalSegments as StrSegment[], horizontalStackedWalls, verticalStackedWalls);
      const existingSplitters = [...beams, ...nonStackedWalls];
      existingSplitters.forEach(beam => {
        if (beam.isHorizontal()) {
          possibleSplitters.horizontal.insert(beam.start.x, beam.end.x, beam);
        } else {
          possibleSplitters.vertical.insert(beam.start.y, beam.end.y, beam);
        }
      });

      const validateFloorSpaces = (floorSpaces: FloorSpace[], affectedSplitters?: Map<Space, Map<StrSegment, Direction>>) => {
        const usedSplitters: StrSegment[] = [];
        const failedFloorSpaces: FloorSpace[] = [];

        for (const floorSpace of floorSpaces) {
          const esrBeamsLength = {
            [Direction.Vertical]: 0,
            [Direction.Horizontal]: 0,
          };
          affectedSplitters?.get(floorSpace.space)?.forEach((spanDirection, segment) => {
            if (segment.type === StrSegmentType.FloorSpanBeam && spanDirection) {
              esrBeamsLength[spanDirection === Direction.Vertical ? Direction.Horizontal : Direction.Vertical] += segment.length();
            }
          });

          const spaceSegments = floorSpace.extractSegments() as StrSegment[];
          let xSites: number[] = [];
          let ySites: number[] = [];

          const horizontalSpaceSegments = new IntervalTree<StrSegment, number>();
          const verticalSpaceSegments = new IntervalTree<StrSegment, number>();
          spaceSegments.forEach(wall => {
            if (wall.isHorizontal()) {
              horizontalSpaceSegments.insert(wall.start.x, wall.end.x, wall);
              xSites.push(wall.start.x, wall.end.x);
            } else {
              verticalSpaceSegments.insert(wall.start.y, wall.end.y, wall);
              ySites.push(wall.start.y, wall.end.y);
            }
          });

          xSites = GeometryUtils.deduplicateSites(xSites);
          ySites = GeometryUtils.deduplicateSites(ySites);

          let spanResults: Array<{ splitters: StrSegment[]; spanDirection: Direction }> = [];
          // left -> right
          spanResults.push({
            splitters: this.checkSpan(
              xSites,
              horizontalSpaceSegments,
              verticalSpaceSegments,
              possibleSplitters.horizontal,
              floorSpace,
              roomBoxes,
              Direction.Vertical
            ),
            spanDirection: Direction.Vertical,
          });
          // right -> left
          xSites.sort((a, b) => b - a);
          spanResults.push({
            splitters: this.checkSpan(
              xSites,
              horizontalSpaceSegments,
              verticalSpaceSegments,
              possibleSplitters.horizontal,
              floorSpace,
              roomBoxes,
              Direction.Vertical
            ),
            spanDirection: Direction.Vertical,
          });

          // bottom -> top
          spanResults.push({
            splitters: this.checkSpan(
              ySites,
              verticalSpaceSegments,
              horizontalSpaceSegments,
              possibleSplitters.vertical,
              floorSpace,
              roomBoxes,
              Direction.Horizontal
            ),
            spanDirection: Direction.Horizontal,
          });
          // top -> bottom
          ySites.sort((a, b) => b - a);
          spanResults.push({
            splitters: this.checkSpan(
              ySites,
              verticalSpaceSegments,
              horizontalSpaceSegments,
              possibleSplitters.vertical,
              floorSpace,
              roomBoxes,
              Direction.Horizontal
            ),
            spanDirection: Direction.Horizontal,
          });

          spanResults = spanResults.filter(r => r.splitters);
          if (spanResults.length) {
            let minBeamsLength = Number.POSITIVE_INFINITY;
            let result: { splitters: StrSegment[]; spanDirection: Direction };
            spanResults.forEach(spanResult => {
              const beamsLength =
                spanResult.splitters.reduce((sum, beam) => (beam.type === StrSegmentType.FloorSpanBeam ? sum + beam.length() : sum), 0) +
                esrBeamsLength[spanResult.spanDirection];
              if (minBeamsLength > beamsLength) {
                minBeamsLength = beamsLength;
                result = spanResult;
              }
            });

            usedSplitters.push(...result.splitters);
            // add splitters used for simple rooms solving
            affectedSplitters?.get(floorSpace.space)?.forEach((spanDirection, segment) => {
              if (spanDirection !== result.spanDirection) {
                if (!segment.isAxisAligned()) {
                  segment.revert();
                }
                usedSplitters.push(segment);
              }
            });
          } else {
            failedFloorSpaces.push(floorSpace);
          }
        }

        return { usedSplitters, failedFloorSpaces };
      };

      const tree = this.buildFloorSpaceTree(stackedWalls);
      let result = validateFloorSpaces(tree.extractFloorSpaces());

      if (result.usedSplitters.some(segment => segment.type === StrSegmentType.FloorSpanBeam)) {
        // nonStackedWalls, beams can be modified
        const affectedSplitters = this.solveSimpleRooms(tree, roomBoxes.slice(), nonStackedWalls, beams);
        const newExistingSplitters = [...beams, ...nonStackedWalls];

        // remove outdated
        existingSplitters.forEach(beam => {
          if (newExistingSplitters.includes(beam)) {
            return;
          }

          if (beam.isHorizontal()) {
            possibleSplitters.horizontal.remove(beam.start.x, beam.end.x, beam);
          } else {
            possibleSplitters.vertical.remove(beam.start.y, beam.end.y, beam);
          }
        });

        // add new
        newExistingSplitters.forEach(beam => {
          if (existingSplitters.includes(beam)) {
            return;
          }

          if (beam.isHorizontal()) {
            possibleSplitters.horizontal.insert(beam.start.x, beam.end.x, beam);
          } else {
            possibleSplitters.vertical.insert(beam.start.y, beam.end.y, beam);
          }
        });

        result = validateFloorSpaces(tree.extractFloorSpaces(), affectedSplitters);
      }

      result.usedSplitters.forEach(splitter => {
        if (splitter.type === StrSegmentType.NonStacked) {
          splitter.type = StrSegmentType.Stacked;
          const idx = nonStackedWalls.indexOf(splitter);
          if (idx !== -1) {
            nonStackedWalls.splice(idx, 1);
          }
          stackedWalls.push(splitter);
        }

        if (splitter.type === StrSegmentType.FloorSpanBeam) {
          beams.push(splitter);
        }
      });

      if (result.failedFloorSpaces.length !== 0) {
        roomBoxesMap.forEach((bb, roomId) => {
          const center = bb.getCenter(new THREE.Vector3());
          if (result.failedFloorSpaces.some(fs => fs.space.containsPoint(center) && !fs.subspaces.some(space => space.containsPoint(center)))) {
            this.maxSpanFailedItemsIds.get(floor.id).push(roomId);
          }
        });
      }
    });
  }

  private getSegmentsOnContour(bb: THREE.Box3, space: Space): { [key in Side]: { isFullyCovered: boolean; segments: StrSegment[] } } {
    const coverage = {
      [Side.Top]: 0,
      [Side.Bottom]: 0,
      [Side.Left]: 0,
      [Side.Right]: 0,
    };
    const segments = {
      [Side.Top]: [],
      [Side.Bottom]: [],
      [Side.Left]: [],
      [Side.Right]: [],
    };

    [...space.contour, ...space.innerSegments].forEach((segment: StrSegment) => {
      if (segment.type === StrSegmentType.FloorSpanBeam) {
        return;
      }

      if (segment.isHorizontal()) {
        const startX = Math.max(Math.min(segment.start.x, segment.end.x), bb.min.x);
        const endX = Math.min(Math.max(segment.start.x, segment.end.x), bb.max.x);
        if (startX < endX - EPSILON) {
          if (MathUtils.areNumbersEqual(segment.start.y, bb.max.y)) {
            coverage[Side.Top] += endX - startX;
            segments[Side.Top].push(segment);
          } else if (MathUtils.areNumbersEqual(segment.start.y, bb.min.y)) {
            coverage[Side.Bottom] += endX - startX;
            segments[Side.Bottom].push(segment);
          }
        }
      } else {
        const startY = Math.max(Math.min(segment.start.y, segment.end.y), bb.min.y);
        const endY = Math.min(Math.max(segment.start.y, segment.end.y), bb.max.y);
        if (startY < endY - EPSILON) {
          if (MathUtils.areNumbersEqual(segment.start.x, bb.max.x)) {
            coverage[Side.Right] += endY - startY;
            segments[Side.Right].push(segment);
          } else if (MathUtils.areNumbersEqual(segment.start.x, bb.min.x)) {
            coverage[Side.Left] += endY - startY;
            segments[Side.Left].push(segment);
          }
        }
      }
    });

    const size = bb.getSize(new THREE.Vector3());

    const result: { [key in Side]: { isFullyCovered: boolean; segments: StrSegment[] } } = {
      [Side.Top]: { isFullyCovered: MathUtils.areNumbersEqual(coverage[Side.Top], size.x), segments: segments[Side.Top] },
      [Side.Right]: { isFullyCovered: MathUtils.areNumbersEqual(coverage[Side.Right], size.y), segments: segments[Side.Right] },
      [Side.Bottom]: { isFullyCovered: MathUtils.areNumbersEqual(coverage[Side.Bottom], size.x), segments: segments[Side.Bottom] },
      [Side.Left]: { isFullyCovered: MathUtils.areNumbersEqual(coverage[Side.Left], size.y), segments: segments[Side.Left] },
    };

    return result;
  }

  private solveRoom(roomBox: THREE.Box3, space: Space): { spanDirection: Direction; contourSegments: StrSegment[] } {
    let spanDirection: Direction;
    const contourSegments = this.getSegmentsOnContour(roomBox, space);
    const size = roomBox.getSize(new THREE.Vector3());

    if (
      contourSegments[Side.Top].isFullyCovered &&
      contourSegments[Side.Bottom].isFullyCovered &&
      (contourSegments[Side.Left].isFullyCovered || contourSegments[Side.Right].isFullyCovered) &&
      MathUtils.isNumberLessOrEqual(size.y, UnitsUtils.getMaxFloorSpan())
    ) {
      spanDirection = Direction.Vertical;
    }

    if (
      contourSegments[Side.Left].isFullyCovered &&
      contourSegments[Side.Right].isFullyCovered &&
      (contourSegments[Side.Top].isFullyCovered || contourSegments[Side.Bottom].isFullyCovered) &&
      MathUtils.isNumberLessOrEqual(size.x, UnitsUtils.getMaxFloorSpan())
    ) {
      spanDirection = Direction.Horizontal;
    }

    if (spanDirection) {
      return {
        spanDirection,
        contourSegments: Object.values(contourSegments).flatMap(it => it.segments),
      };
    }

    return null;
  }

  // Simple room - room that has at least three covered sides
  private solveSimpleRooms(
    tree: FloorSpaceTree,
    roomBoxes: THREE.Box3[],
    nonStackedWalls: StrSegment[],
    beams: StrSegment[]
  ): Map<Space, Map<StrSegment, Direction>> {
    const affectedSplitters = new Map<Space, Map<StrSegment, Direction>>();

    // Returns list of segments lying on the segment and have no overlap with clippers
    const clipSegment = (segment: StrSegment, clippers: StrSegment[]): StrSegment[] => {
      const clipped: StrSegment[] = [];

      const clipperRanges = clippers
        .filter(clipper => segment.overlapsSegment(clipper))
        .map(it => it.getRange())
        .sort((a, b) => a[0] - b[0]);

      const segmentRange = segment.getRange();

      const addClipped = (start: number, end: number) => {
        if (segment.isHorizontal()) {
          clipped.push(new StrSegment(new THREE.Vector2(start, segment.start.y), new THREE.Vector2(end, segment.start.y), segment.type));
        } else {
          clipped.push(new StrSegment(new THREE.Vector2(segment.start.x, start), new THREE.Vector2(segment.start.x, end), segment.type));
        }
      };

      let start = segmentRange[0];
      for (const clipperRange of clipperRanges) {
        if (start < clipperRange[0] - EPSILON) {
          addClipped(start, clipperRange[0]);
        }
        start = clipperRange[1];
      }

      if (start < segmentRange[1] - EPSILON) {
        addClipped(start, segmentRange[1]);
      }

      return clipped;
    };

    const extractBox = (space: Space, roomBoxes: THREE.Box3[]) => {
      const boxesInSpaceCount = roomBoxes.reduce((count, bb) => count + (space.containsPoint(bb.getCenter(new THREE.Vector3())) ? 1 : 0), 0);
      const splitters = affectedSplitters.get(space);
      for (let i = 0; i < roomBoxes.length; i++) {
        const bb = roomBoxes[i];
        const solution = this.solveRoom(bb, space);

        if (solution) {
          const segments = space.contour as StrSegment[];

          let newContour: StrSegment[];
          let newInner: StrSegment[];
          let clipped: StrSegment[];
          // More than one room in space
          if (boxesInSpaceCount > 1) {
            const boxSegments = [
              new StrSegment(new THREE.Vector2(bb.min.x, bb.max.y), new THREE.Vector2(bb.max.x, bb.max.y), StrSegmentType.FloorSpanBeam),
              new StrSegment(new THREE.Vector2(bb.max.x, bb.min.y), new THREE.Vector2(bb.max.x, bb.max.y), StrSegmentType.FloorSpanBeam),
              new StrSegment(new THREE.Vector2(bb.min.x, bb.min.y), new THREE.Vector2(bb.max.x, bb.min.y), StrSegmentType.FloorSpanBeam),
              new StrSegment(new THREE.Vector2(bb.min.x, bb.min.y), new THREE.Vector2(bb.min.x, bb.max.y), StrSegmentType.FloorSpanBeam),
            ];

            const overlappedInnerSegments = space.innerSegments.filter(inner => boxSegments.some(s => s.overlapsSegment(inner))) as StrSegment[];
            overlappedInnerSegments.forEach(it => space.innerSegments.splice(space.innerSegments.indexOf(it), 1));
            clipped = boxSegments.flatMap(s => clipSegment(s, overlappedInnerSegments));

            segments.push(...clipped, ...overlappedInnerSegments);

            const splitted = SegmentsUtils.splitIntersectedSegments(segments) as StrSegment[];
            const graph = new GraphManager<StrSegment>();
            splitted.forEach(segment => graph.createEdgeFromPoints(segment.start, segment.end, segment));

            const getStartEdge = () => {
              let horizontal: Edge<StrSegment>, vertical: Edge<StrSegment>;
              const expandedBb = bb.clone().expandByScalar(EPSILON);
              expandedBb.min.z = expandedBb.max.z = 0;

              graph.getEdges().forEach(edge => {
                if (
                  expandedBb.containsPoint(GeometryUtils.Vector2ToVector3(edge.data.start)) &&
                  expandedBb.containsPoint(GeometryUtils.Vector2ToVector3(edge.data.end))
                ) {
                  return;
                }

                if (edge.data.isHorizontal()) {
                  if (!horizontal || horizontal.data.start.y > edge.data.start.y) {
                    horizontal = edge;
                  }
                } else {
                  if (!vertical || vertical.data.start.x < edge.data.start.x) {
                    vertical = edge;
                  }
                }
              });

              return horizontal || vertical;
            };

            const path = SegmentsUtils.graphTraverse(getStartEdge(), graph, false);
            newContour = path.contour.map(edge => edge.data) as StrSegment[];
            newInner = path.innerEdges.map(edge => edge.data) as StrSegment[];
          } else {
            newContour = [];
            newInner = [];
            clipped = [];
          }

          space.innerSegments.push(...newInner);

          // set spanDirection for added beams
          newContour.forEach((segment, idx) => {
            if (segment.type === StrSegmentType.FloorSpanBeam && splitters.get(segment) === undefined) {
              const existingSplitter = [...nonStackedWalls, ...beams].find(splitter => segment.overlapsSegment(splitter));
              if (existingSplitter) {
                // take only part needed for contour segment
                const clipped = clipSegment(existingSplitter, [segment]);
                const newSplitter = segment.clone();
                newSplitter.type = existingSplitter.type;

                // Remove existingSplitter, add cut part and rest of the existingSplitter. Cut part may be converted into a segment of another type.
                const existingSplitters = existingSplitter.type === StrSegmentType.NonStacked ? nonStackedWalls : beams;
                existingSplitters.splice(existingSplitters.indexOf(existingSplitter), 1, ...clipped, newSplitter);

                newContour.splice(idx, 1, newSplitter);
                splitters.set(newSplitter, solution.spanDirection);
              } else {
                splitters.set(segment, solution.spanDirection);
              }
            }
          });

          segments.forEach(segment => {
            if (
              (segment.type === StrSegmentType.FloorSpanBeam || segment.type === StrSegmentType.Beam || segment.type === StrSegmentType.NonStacked) &&
              !newContour.includes(segment) &&
              !clipped.includes(segment)
            ) {
              if (splitters.get(segment) !== solution.spanDirection) {
                splitters.set(segment, null);
              } else {
                splitters.delete(segment);
              }
            }
          });

          space.contour = newContour;
          roomBoxes.splice(i, 1);

          return true;
        }
      }

      return null;
    };

    const spaces = tree.postOrder();

    spaces.forEach(space => {
      affectedSplitters.set(space, new Map<StrSegment, Direction>());
      const spaceBoxes: THREE.Box3[] = [];
      // extract current space boxes
      for (let i = 0; i < roomBoxes.length; i++) {
        const bb = roomBoxes[i];
        if (space.containsPoint(bb.getCenter(new THREE.Vector3()))) {
          spaceBoxes.push(bb);
          roomBoxes.splice(i, 1);
          i--;
        }
      }

      while (extractBox(space, spaceBoxes));
    });

    [...beams, ...nonStackedWalls].forEach(it => !it.isAxisAligned() && it.revert());

    return affectedSplitters;
  }

  private maxCantileverCheck() {
    for (const floor of appModel.activeCorePlan.floors) {
      this.maxCantileverFailedItemsIds.set(floor.id, []);

      const floorBelow = appModel.activeCorePlan.floors.find(f => f.index === floor.index - 1);
      if (!floorBelow) {
        continue;
      }

      const indoorSoRoomsBelow = floorBelow.rooms
        .filter(room => appModel.getRoomType(room.roomTypeId).attributes.indoor)
        .map(room => this.roomManager.getCorePlanSoRoom(room.id));
      const soRoomsBelowBoxes = indoorSoRoomsBelow.map(soRoom => SceneUtils.getRoomBoundingBoxByModelLines(soRoom));

      const floatingSoRooms = floor.rooms
        .map(room => this.roomManager.getCorePlanSoRoom(room.id))
        .filter(soRoom => this.isBoxFloating(SceneUtils.getRoomBoundingBoxByModelLines(soRoom), soRoomsBelowBoxes));

      for (const floatingSoRoom of floatingSoRooms) {
        const segments = WallAnalysisUtils.collectSegments([...indoorSoRoomsBelow, floatingSoRoom], MergeSegmentsMode.Disabled, false);

        const floatingSoRoomBb = SceneUtils.getRoomBoundingBoxByModelLines(floatingSoRoom);
        floatingSoRoomBb.expandByScalar(EPSILON);
        floatingSoRoomBb.min.z = floatingSoRoomBb.max.z = 0;

        const externalWallsBelow = this.stackedWalls.get(floorBelow.id).filter(segment => segment.type === StrSegmentType.Exterior);

        const outdoorExternalSegments = segments.externalSegments.filter(segment => {
          if (segment.roomId === floatingSoRoom.userData.id) {
            const center = segment.getCenter3();
            return !soRoomsBelowBoxes.some(bb => bb.containsPoint(center));
          }
          return false;
        }) as StrSegment[];

        const splittedSegments = SegmentsUtils.splitIntersectedSegments([...outdoorExternalSegments, ...externalWallsBelow]).filter(seg =>
          floatingSoRoomBb.containsPoint(seg.getCenter3())
        ) as StrSegment[];
        const floorSpaces = this.buildFloorSpaceTree(splittedSegments).extractFloorSpaces();

        for (const floorSpace of floorSpaces) {
          const spaceSegments = floorSpace.extractSegments();
          const horizontalSegments = new IntervalTree<StrSegment, number>();
          const verticalSegments = new IntervalTree<StrSegment, number>();
          let xSites: number[] = [];
          let ySites: number[] = [];

          spaceSegments.forEach((segment: StrSegment) => {
            if (segment.isHorizontal()) {
              horizontalSegments.insert(segment.start.x, segment.end.x, segment);
              xSites.push(segment.start.x, segment.end.x);
            } else {
              verticalSegments.insert(segment.start.y, segment.end.y, segment);
              ySites.push(segment.start.y, segment.end.y);
            }
          });

          xSites = GeometryUtils.deduplicateSites(xSites);
          ySites = GeometryUtils.deduplicateSites(ySites);

          const vertical = this.checkCantileverSpan(xSites, horizontalSegments, soRoomsBelowBoxes, Direction.Vertical);
          const horizontal = this.checkCantileverSpan(ySites, verticalSegments, soRoomsBelowBoxes, Direction.Horizontal);

          if (!vertical && !horizontal) {
            this.maxCantileverFailedItemsIds.get(floor.id).push(floatingSoRoom.userData.id);
            break;
          }
        }
      }
    }
  }

  private collectBeams(
    internalSegments: StrSegment[],
    horizontalBearingWalls: IntervalTree<StrSegment, number>,
    verticalBearingWalls: IntervalTree<StrSegment, number>
  ): { horizontal: IntervalTree<StrSegment, number>; vertical: IntervalTree<StrSegment, number> } {
    const isValidHorizontalBeam = (startX: number, endX: number, y: number) => {
      return (
        horizontalBearingWalls.search(startX + EPSILON, endX - EPSILON).filter(wall => MathUtils.areNumbersEqual(wall.start.y, y, UnitsUtils.getMinFloorSpan()))
          .length === 0
      );
    };
    const isValidVerticalBeam = (startY: number, endY: number, x: number) => {
      return (
        verticalBearingWalls.search(startY + EPSILON, endY - EPSILON).filter(wall => MathUtils.areNumbersEqual(wall.start.x, x, UnitsUtils.getMinFloorSpan()))
          .length === 0
      );
    };

    const horizontal = new IntervalTree<StrSegment, number>();
    const vertical = new IntervalTree<StrSegment, number>();

    const horizontalBearingWallsList = Array.from(horizontalBearingWalls.inOrder())
      .map(node => node.data)
      .sort((a, b) => a.start.y - b.start.y);
    const verticalBearingWallsList = Array.from(verticalBearingWalls.inOrder())
      .map(node => node.data)
      .sort((a, b) => a.start.x - b.start.x);

    internalSegments.forEach(segment => {
      if (segment.isHorizontal()) {
        const sites = verticalBearingWalls
          .search(segment.start.y - EPSILON, segment.start.y + EPSILON)
          .sort((a, b) => a.start.y - b.start.y)
          .map(seg => seg.start.x);
        const hSites = horizontalBearingWallsList.filter(s => MathUtils.areNumbersEqual(s.start.y, segment.start.y)).flatMap(s => [s.start.x, s.end.x]);
        sites.push(...hSites);
        const center = segment.getCenter3();
        let nearestSmaller: number = Number.NEGATIVE_INFINITY;
        let nearestGreater: number = Number.POSITIVE_INFINITY;

        sites.forEach(site => {
          if (site < center.x && site > nearestSmaller) {
            nearestSmaller = site;
          }
          if (site > center.x && site < nearestGreater) {
            nearestGreater = site;
          }
        });

        if (Number.isFinite(nearestSmaller) && Number.isFinite(nearestGreater)) {
          if (isValidHorizontalBeam(nearestSmaller, nearestGreater, segment.start.y)) {
            const beam = new StrSegment(
              new THREE.Vector2(nearestSmaller, segment.start.y),
              new THREE.Vector2(nearestGreater, segment.start.y),
              StrSegmentType.FloorSpanBeam
            );
            beam.isOnEdge = true;
            horizontal.insert(beam.start.x, beam.end.x, beam);
          }
        }
      } else {
        const sites = horizontalBearingWalls
          .search(segment.start.x - EPSILON, segment.start.x + EPSILON)
          .sort((a, b) => a.start.x - b.start.x)
          .map(seg => seg.start.y);
        const vSites = verticalBearingWallsList
          .filter(s => MathUtils.areNumbersEqual(s.start.x, segment.start.x))
          .map(s => [s.start.y, s.end.y])
          .flat();
        sites.push(...vSites);
        const center = segment.getCenter3();
        let nearestSmaller: number = Number.NEGATIVE_INFINITY;
        let nearestGreater: number = Number.POSITIVE_INFINITY;

        sites.forEach(site => {
          if (site < center.y && site > nearestSmaller) {
            nearestSmaller = site;
          }
          if (site > center.y && site < nearestGreater) {
            nearestGreater = site;
          }
        });

        if (Number.isFinite(nearestSmaller) && Number.isFinite(nearestGreater)) {
          if (isValidVerticalBeam(nearestSmaller, nearestGreater, segment.start.x)) {
            const beam = new StrSegment(
              new THREE.Vector2(segment.start.x, nearestSmaller),
              new THREE.Vector2(segment.start.x, nearestGreater),
              StrSegmentType.FloorSpanBeam
            );
            beam.isOnEdge = true;
            vertical.insert(beam.start.y, beam.end.y, beam);
          }
        }
      }
    });

    let beamSites = verticalBearingWallsList.flatMap(seg => [
      new StrSegment(seg.start.clone(), seg.start.clone()),
      new StrSegment(seg.end.clone(), seg.end.clone()),
    ]);
    beamSites.push(...horizontalBearingWallsList);
    beamSites.forEach(beamSite => {
      const segments = verticalBearingWalls
        .search(beamSite.start.y - EPSILON, beamSite.start.y + EPSILON)
        .filter(s => !MathUtils.areNumbersEqual(s.start.x, beamSite.start.x) && !MathUtils.areNumbersEqual(s.start.x, beamSite.end.x));

      let nearestSmaller: number = Number.NEGATIVE_INFINITY;
      let nearestGreater: number = Number.POSITIVE_INFINITY;
      segments.forEach(segment => {
        if (segment.start.x < beamSite.start.x && segment.start.x > nearestSmaller) {
          nearestSmaller = segment.start.x;
        }
        if (segment.start.x > beamSite.end.x && segment.start.x < nearestGreater) {
          nearestGreater = segment.start.x;
        }
      });

      if (Number.isFinite(nearestSmaller)) {
        if (isValidHorizontalBeam(nearestSmaller, beamSite.start.x, beamSite.start.y)) {
          const beam = new StrSegment(
            new THREE.Vector2(nearestSmaller, beamSite.start.y),
            new THREE.Vector2(beamSite.start.x, beamSite.start.y),
            StrSegmentType.FloorSpanBeam
          );
          horizontal.insert(beam.start.x, beam.end.x, beam);
        }
      }

      if (Number.isFinite(nearestGreater)) {
        if (isValidHorizontalBeam(beamSite.end.x, nearestGreater, beamSite.start.y)) {
          const beam = new StrSegment(
            new THREE.Vector2(beamSite.end.x, beamSite.start.y),
            new THREE.Vector2(nearestGreater, beamSite.start.y),
            StrSegmentType.FloorSpanBeam
          );
          horizontal.insert(beam.start.x, beam.end.x, beam);
        }
      }
    });

    beamSites = horizontalBearingWallsList.flatMap(s => [new StrSegment(s.start.clone(), s.start.clone()), new StrSegment(s.end.clone(), s.end.clone())]);
    beamSites.push(...verticalBearingWallsList);
    beamSites.forEach(beamSite => {
      const segments = horizontalBearingWalls
        .search(beamSite.start.x - EPSILON, beamSite.start.x + EPSILON)
        .filter(s => !MathUtils.areNumbersEqual(s.start.y, beamSite.start.y) && !MathUtils.areNumbersEqual(s.start.y, beamSite.end.y));

      let nearestSmaller: number = Number.NEGATIVE_INFINITY;
      let nearestGreater: number = Number.POSITIVE_INFINITY;
      segments.forEach(segment => {
        if (segment.start.y < beamSite.start.y && segment.start.y > nearestSmaller) {
          nearestSmaller = segment.start.y;
        }
        if (segment.start.y > beamSite.end.y && segment.start.y < nearestGreater) {
          nearestGreater = segment.start.y;
        }
      });

      if (Number.isFinite(nearestSmaller)) {
        if (isValidVerticalBeam(nearestSmaller, beamSite.start.y, beamSite.start.x)) {
          const beam = new StrSegment(
            new THREE.Vector2(beamSite.start.x, nearestSmaller),
            new THREE.Vector2(beamSite.start.x, beamSite.start.y),
            StrSegmentType.FloorSpanBeam
          );
          vertical.insert(beam.start.y, beam.end.y, beam);
        }
      }

      if (Number.isFinite(nearestGreater)) {
        if (isValidVerticalBeam(beamSite.end.y, nearestGreater, beamSite.start.x)) {
          const beam = new StrSegment(
            new THREE.Vector2(beamSite.start.x, beamSite.end.y),
            new THREE.Vector2(beamSite.start.x, nearestGreater),
            StrSegmentType.FloorSpanBeam
          );
          vertical.insert(beam.start.y, beam.end.y, beam);
        }
      }
    });

    return { horizontal, vertical };
  }
  private collectBeamsOnInterval(
    low: number,
    hight: number,
    startPosition: number,
    bearingWalls: IntervalTree<StrSegment, number>,
    direction: Direction
  ): StrSegment[] {
    const maxFloorSpan = UnitsUtils.getMaxFloorSpan();
    if (MathUtils.areNumbersEqual(maxFloorSpan, 0)) {
      return [];
    }

    const axis1 = direction === Direction.Vertical ? "x" : "y";
    const axis2 = direction === Direction.Vertical ? "y" : "x";

    const beams: StrSegment[] = [];
    const span = hight - low;
    const beamCount = Math.ceil(span / maxFloorSpan) - 1;

    for (let i = 1; i <= beamCount; i++) {
      const beamSite = low + (span / (beamCount + 1)) * i;
      const sites = bearingWalls
        .search(beamSite - EPSILON, beamSite + EPSILON)
        .sort((a, b) => a.start[axis2] - b.start[axis2])
        .map(seg => seg.start[axis1]);
      let nearestSmaller: number = Number.NEGATIVE_INFINITY;
      let nearestGreater: number = Number.POSITIVE_INFINITY;

      sites.forEach(site => {
        if (site < startPosition && site > nearestSmaller) {
          nearestSmaller = site;
        }
        if (site > startPosition && site < nearestGreater) {
          nearestGreater = site;
        }
      });

      if (Number.isFinite(nearestSmaller) && Number.isFinite(nearestGreater)) {
        const beam = new StrSegment(
          direction === Direction.Vertical ? new THREE.Vector2(nearestSmaller, beamSite) : new THREE.Vector2(beamSite, nearestSmaller),
          direction === Direction.Vertical ? new THREE.Vector2(nearestGreater, beamSite) : new THREE.Vector2(beamSite, nearestGreater),
          StrSegmentType.FloorSpanBeam
        );
        beams.push(beam);
      } else {
        return [];
      }
    }

    return beams;
  }

  private checkSpan(
    sites: number[],
    bearingWalls: IntervalTree<StrSegment, number>,
    otherDirectionBearingWalls: IntervalTree<StrSegment, number>,
    possibleSplitters: IntervalTree<StrSegment, number>,
    floorSpace: FloorSpace,
    roomBoxes: THREE.Box3[],
    direction: Direction
  ): StrSegment[] | null {
    const axis1 = direction === Direction.Vertical ? "x" : "y";
    const axis2 = direction === Direction.Vertical ? "y" : "x";
    let hasError = false;

    for (const site of sites) {
      const bearingWallsList = bearingWalls.search(site + EPSILON, site + 2 * EPSILON).sort((a, b) => a.start[axis2] - b.start[axis2]);
      if (bearingWallsList.length === 0) {
        continue;
      }

      for (let i = 1; i < bearingWallsList.length; i++) {
        const point =
          direction === Direction.Vertical
            ? new THREE.Vector3(site + EPSILON, bearingWallsList[i - 1].start.y + EPSILON)
            : new THREE.Vector3(bearingWallsList[i - 1].start.x + EPSILON, site + EPSILON);

        if (!roomBoxes.some(bb => bb.containsPoint(point)) || floorSpace.subspaces.some(space => space.containsPoint(point))) {
          continue;
        }

        const span = bearingWallsList[i].start[axis2] - bearingWallsList[i - 1].start[axis2];

        if (span > UnitsUtils.getMaxFloorSpan()) {
          const splitters = possibleSplitters
            .search(site + EPSILON, site + 2 * EPSILON)
            .filter(
              splitter =>
                (splitter.type !== StrSegmentType.FloorSpanBeam &&
                  splitter.start[axis2] > bearingWallsList[i - 1].start[axis2] &&
                  splitter.start[axis2] < bearingWallsList[i].start[axis2]) ||
                (splitter.type === StrSegmentType.FloorSpanBeam &&
                  splitter.start[axis2] > bearingWallsList[i - 1].start[axis2] + UnitsUtils.getMinFloorSpan() &&
                  splitter.start[axis2] < bearingWallsList[i].start[axis2] - UnitsUtils.getMinFloorSpan())
            );

          if (splitters.length === 0) {
            const newBeams = this.collectBeamsOnInterval(
              bearingWallsList[i - 1].start[axis2],
              bearingWallsList[i].start[axis2],
              site + EPSILON,
              otherDirectionBearingWalls,
              direction
            );
            newBeams.forEach(beam => {
              possibleSplitters.insert(beam.start[axis1], beam.end[axis1], beam);
              splitters.push(beam);
            });
          }

          splitters.push(bearingWallsList[i], bearingWallsList[i - 1]);
          splitters.sort((a, b) => a.start[axis2] - b.start[axis2]);

          const cost: number[] = [];
          const path: number[] = [];
          cost[splitters.length - 1] = 0;
          path[splitters.length - 1] = -1;

          const calculateCost = (segment: StrSegment): number => {
            if (
              segment.isUsed ||
              segment.type === StrSegmentType.Beam ||
              segment.type === StrSegmentType.NonStacked ||
              segment === bearingWallsList[i] ||
              segment === bearingWallsList[i - 1]
            ) {
              return 0;
            }

            let cost = segment.length();
            if (segment.isOnEdge) {
              cost -= 10 * EPSILON;
            }

            return cost;
          };

          for (let i = splitters.length - 2; i >= 0; i--) {
            let minJ = Number.POSITIVE_INFINITY;
            let minCost = Number.POSITIVE_INFINITY;
            for (let j = i + 1; j < splitters.length; j++) {
              if (!MathUtils.isNumberLessOrEqual(splitters[j].start[axis2] - splitters[i].start[axis2], UnitsUtils.getMaxFloorSpan())) {
                break;
              }

              if (MathUtils.isNumberLessOrEqual(cost[j], minCost)) {
                minCost = cost[j];
                minJ = j;
              }
            }

            if (minCost === Number.POSITIVE_INFINITY) {
              hasError = true;
              break;
            }

            cost[i] = calculateCost(splitters[i]) + minCost;
            path[i] = minJ;
          }

          if (hasError) {
            break;
          }

          //restore path
          let j = path[0];
          while (path[j] !== -1) {
            splitters[j].isUsed = true;
            j = path[j];
          }
        }
      }

      if (hasError) {
        break;
      }
    }

    const usedSplitters = Array.from(possibleSplitters.inOrder())
      .map(node => node.data)
      .filter(splitter => splitter.isUsed);

    //reset state
    usedSplitters.forEach(splitter => {
      splitter.isUsed = false;
    });

    if (hasError) {
      return null;
    }

    // clip used FloorSpanBeams intersected by NonStackedWalls
    const result: StrSegment[] = [];
    usedSplitters.forEach(splitter => {
      if (splitter.type !== StrSegmentType.FloorSpanBeam) {
        result.push(splitter);
        return;
      }

      // As FloorSpanBeam lies on two stacked walls, then NonStackedWall can only be inside the beam
      const wallsInside = usedSplitters.filter(
        wall =>
          wall.type === StrSegmentType.NonStacked &&
          MathUtils.areNumbersEqual(splitter.start[axis2], wall.start[axis2]) &&
          MathUtils.isNumberLessOrEqual(splitter.start[axis1], wall.start[axis1]) &&
          MathUtils.isNumberLessOrEqual(wall.end[axis1], splitter.end[axis1])
      );
      wallsInside.sort((a, b) => a.start[axis1] - b.start[axis1]);

      if (wallsInside.length === 0) {
        result.push(splitter);
        return;
      }

      for (let i = 0; i <= wallsInside.length; i++) {
        const start = i === 0 ? splitter.start.clone() : wallsInside[i - 1].end.clone();
        const end = i === wallsInside.length ? splitter.end.clone() : wallsInside[i].start.clone();
        if (!MathUtils.areNumbersEqual(start.x, end.x)) {
          result.push(new StrSegment(start, end, StrSegmentType.FloorSpanBeam));
        }
      }
    });

    return result;
  }
  private checkCantileverSpan(sites: number[], segments: IntervalTree<StrSegment, number>, soRoomsBelowBoxes: THREE.Box3[], direction: Direction): boolean {
    if (direction === Direction.Vertical) {
      for (const site of sites) {
        const segmentsList = segments.search(site + EPSILON, site + 2 * EPSILON).sort((a, b) => a.start.y - b.start.y);

        for (let i = 1; i < segmentsList.length; i++) {
          const segment = segmentsList[i];
          const prevSegment = segmentsList[i - 1];
          const distance = segment.start.y - prevSegment.start.y;

          if (!prevSegment.type && !segment.type) {
            return false;
          }

          if (
            ((!prevSegment.type && segment.type === StrSegmentType.Exterior) || (prevSegment.type === StrSegmentType.Exterior && !segment.type)) &&
            !MathUtils.isNumberLessOrEqual(distance, UnitsUtils.getMaxCantileverSpan())
          ) {
            return false;
          }

          if (prevSegment.type === StrSegmentType.Exterior && segment.type === StrSegmentType.Exterior) {
            const point = new THREE.Vector3(site + EPSILON, prevSegment.start.y + EPSILON);
            if (soRoomsBelowBoxes.some(bb => bb.containsPoint(point))) {
              continue;
            }

            if (!MathUtils.isNumberLessOrEqual(distance, UnitsUtils.getMaxFloorSpan())) {
              return false;
            }
          }
        }
      }
    } else {
      for (const site of sites) {
        const segmentsList = segments.search(site + EPSILON, site + 2 * EPSILON).sort((a, b) => a.start.x - b.start.x);

        for (let i = 1; i < segmentsList.length; i++) {
          const segment = segmentsList[i];
          const prevSegment = segmentsList[i - 1];
          const distance = segment.start.x - prevSegment.start.x;

          if (!prevSegment.type && !segment.type) {
            return false;
          }

          if (
            ((!prevSegment.type && segment.type === StrSegmentType.Exterior) || (prevSegment.type === StrSegmentType.Exterior && !segment.type)) &&
            !MathUtils.isNumberLessOrEqual(distance, UnitsUtils.getMaxCantileverSpan())
          ) {
            return false;
          }

          if (prevSegment.type === StrSegmentType.Exterior && segment.type === StrSegmentType.Exterior) {
            const point = new THREE.Vector3(prevSegment.start.x + EPSILON, site + EPSILON);
            if (soRoomsBelowBoxes.some(bb => bb.containsPoint(point))) {
              continue;
            }

            if (!MathUtils.isNumberLessOrEqual(distance, UnitsUtils.getMaxFloorSpan())) {
              return false;
            }
          }
        }
      }
    }

    return true;
  }

  private isBoxFloating(box: THREE.Box3, boxesBelow: THREE.Box3[]): boolean {
    const intersectionArea = boxesBelow.reduce((area, boxBelow) => {
      const size = box.clone().intersect(boxBelow).getSize(new THREE.Vector3());
      return area + size.x * size.y;
    }, 0);

    const boxSize = box.getSize(new THREE.Vector3());
    return intersectionArea < boxSize.x * boxSize.y;
  }
}
