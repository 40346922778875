import { observer } from "mobx-react";
import { Tooltip } from "antd";

import { SceneEditorMode } from "../../../editor/models/SceneEditorMode";
import { appModel } from "../../../models/AppModel";

import RoomEditToolPosition from "../../../editor/tools/RoomEditToolPosition";

import "./Editor.sass";

export interface IEditorToolOptions {
  show: boolean;
  top?: number;
  left?: number;
}

const EditorToolbar = observer(() => {
  const options = appModel.editToolOptions;

  if (!options || !options.show || (!options?.top && !options?.left)) {
    return null;
  }

  const isEnabledRooms =
    !appModel.isViewOnlyMode &&
    appModel.sceneEditorMode === SceneEditorMode.Room &&
    appModel.activeCorePlan !== null &&
    appModel.activeCorePlan.getRooms(appModel.selectedRoomsIds).length > 0;

  return (
    <div className="editor-toolbar-container" style={{ left: `${options.left}px`, top: `${options.top}px` }}>
      <div className="editor-toolbar">
        <div className="editor-toolbar">
          <Tooltip title="Rotate" placement="bottom">
            <button className="btn btn-icon icon" disabled={!isEnabledRooms} onClick={onRotateRoomsClockwiseClicked} data-testid="rotate-room(s)-clockwise">
              <img src="/assets/icons/rotate-left.svg" alt="rotate-left" />
            </button>
          </Tooltip>
          <Tooltip title="Rotate Inner" placement="bottom">
            <button
              className="btn btn-icon icon"
              disabled={!isEnabledRooms}
              onClick={onRotateRoomsContentClockwiseClicked}
              data-testid="rotate-room(s)-furniture-clockwise"
            >
              <img src="/assets/icons/rotate-inner.svg" alt="rotate-inner" />
            </button>
          </Tooltip>
          <hr />
          <Tooltip title="Mirror H" placement="bottom">
            <button
              className="btn btn-icon icon"
              disabled={!isEnabledRooms}
              onClick={onMirrorRoomsHorizontallyClicked}
              data-testid="mirror-room(s)-horizontally"
            >
              <img src="/assets/icons/mirror-horizontally.svg" alt="mirror-horizontally" />
            </button>
          </Tooltip>
          <Tooltip title="Mirror V" placement="bottom">
            <button className="btn btn-icon icon" disabled={!isEnabledRooms} onClick={onMirrorRoomsVerticallyClicked} data-testid="mirror-room(s)-vertically">
              <img src="/assets/icons/mirror-vertically.svg" alt="mirror-vertically" />
            </button>
          </Tooltip>
          {/* <hr /> */}
          {/* <Tooltip title="Duplicate" placement="bottom">
            <button className="btn btn-icon icon" disabled={!isEnabledRooms} onClick={onMirrorRoomsVerticallyClicked} data-testid="mirror-room(s)-vertically">
              <img src="/assets/icons/duplicate.svg" alt="duplicate" />
            </button>
          </Tooltip> */}
          {/* <hr />
          <Tooltip title="Undo" placement="bottom">
            <button className="btn btn-icon icon icon-back" disabled={!isEnabledUndoRedo} onClick={onUndoClicked} data-testid="undo" />
          </Tooltip>
          <Tooltip title="Redo" placement="bottom">
            <button className="btn btn-icon icon icon-front" disabled={!isEnabledUndoRedo} onClick={onRedoClicked} data-testid="redo" />
          </Tooltip> */}
        </div>
      </div>
    </div>
  );
});
// Todo - delete comments
// const onUndoClicked = (e: React.MouseEvent): void => {
//   appModel.undo();
// };
// const onRedoClicked = (e: React.MouseEvent): void => {
//   appModel.redo();
// };
// const onRotateBackgroundClockwiseClicked = (e: React.MouseEvent): void => {
//   appModel.baseManager.roomManager.backgroundManager.rotateBackground(true);
// };
// const onRotateBackgroundCounterclockwiseClicked = (e: React.MouseEvent): void => {
//   appModel.baseManager.roomManager.backgroundManager.rotateBackground(false);
// };

const onRotateRoomsClockwiseClicked = (e: React.MouseEvent): void => {
  appModel.baseManager.roomManager.rotateSelectedRooms(true);

  RoomEditToolPosition.setPosition(true);
};
// const onRotateRoomsCounterclockwiseClicked = (e: React.MouseEvent): void => {
//   appModel.baseManager.roomManager.rotateSelectedRooms(false);
// };
const onRotateRoomsContentClockwiseClicked = (e: React.MouseEvent): void => {
  appModel.baseManager.roomManager.rotateSelectedRoomsContent(true);
};
// const onRotateRoomsContentCounterclockwiseClicked = (e: React.MouseEvent): void => {
//   appModel.baseManager.roomManager.rotateSelectedRoomsContent(false);
// };
const onMirrorRoomsVerticallyClicked = (e: React.MouseEvent): void => {
  appModel.baseManager.roomManager.mirrorSelectedRooms(false);
};
const onMirrorRoomsHorizontallyClicked = (e: React.MouseEvent): void => {
  appModel.baseManager.roomManager.mirrorSelectedRooms(true);
};
// const onFlorBackgroundScaleClicked = (e: React.MouseEvent): void => {
//   appModel.baseManager.roomManager.backgroundManager.toggleScaleMode();
// };
// const onOpeningUnlockClicked = (e: React.MouseEvent): void => {
//   appModel.baseManager.roomManager.roomOpeningManager.unlockSelectedOpening();
// };

export default EditorToolbar;
