import { useEffect, RefObject } from "react";
import { appModel } from "../../models/AppModel";
import { SceneEditorMode } from "../../editor/models/SceneEditorMode";

function useClickOutside(ref: RefObject<HTMLElement>, onClickOutside: (e) => void) {
  useEffect(() => {
    function handleOutsideClick(event: PointerEvent) {
      // if click outside of canvas and selectedRoomWall is not empty - reset room selection
      if (ref.current && !ref.current.contains(event.target as Node) && appModel.selectedRoomWall?.size) {
        appModel.setSceneEditorMode(SceneEditorMode.Room);
        onClickOutside(event);
      }
    }

    document.addEventListener("pointerdown", handleOutsideClick, { capture: true });

    return () => {
      document.removeEventListener("pointerdown", handleOutsideClick, { capture: true });
    };
  }, [ref, onClickOutside]);
}

export default useClickOutside;
